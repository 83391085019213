import React from 'react';

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Container,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useSendQuestionMutation } from '../redux/services/auth';
import { emailValidation, requiredValidation } from './form-validation';
import { IFeedbackQuestionCreate } from './types';

const categories = [
  { value: 'question', name: 'Вопрос' },
  { value: 'suggestion', name: 'Предложение' },
  { value: 'complaint', name: 'Жалоба' },
  { value: 'review', name: 'Отзыв' },
  { value: 'information_request', name: 'Запрос информации' },
  { value: 'technical_support', name: 'Техническая поддержка' },
  { value: 'financial_issue', name: 'Финансовый вопрос' },
  { value: 'thanks', name: 'Благодарность' },
  { value: 'other', name: 'Другое' },
];

export const FeedbackComponent = () => {
  const [sendQuestion, sendQuestionResult] = useSendQuestionMutation();
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IFeedbackQuestionCreate>({
    defaultValues: {
      email: '',
      name: '',
      category: '',
      message: '',
    },
    shouldUnregister: true,
  });

  React.useEffect(() => {
    document.title = 'Обратная связь | КлинОк';
  }, []);

  const onSubmit = async (data: any) => {
    try {
      await sendQuestion(data)
        .unwrap()
        .then(() => {
          reset();
          toast.success('Ваше обращение успешно зарегистрировано');
        });
    } catch (err) {
      toast.error('Произошла ошибка');
    }
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ flexGrow: 1, pb: 8 }}>
      <Box
        sx={{
          marginTop: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: 10,
        }}
      >
        <Typography component="h1" variant="h5" sx={{ mt: 1 }}>
          Форма обратной связи
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            rules={emailValidation}
            render={({ field }) => (
              <TextField
                data-cy="input-car-plate"
                label="Электронная почта"
                margin="normal"
                sx={{ mt: 3, mb: 0 }}
                fullWidth
                size="small"
                onChange={async (e) => {
                  field.onChange(e.target.value);
                }}
                value={field.value}
                error={!!errors.email?.message}
                helperText={errors.email?.message}
              />
            )}
          />
          <Controller
            name="name"
            control={control}
            rules={requiredValidation}
            render={({ field }) => (
              <TextField
                data-cy="name"
                label="Имя"
                margin="normal"
                sx={{ mt: 1 }}
                fullWidth
                size="small"
                onChange={async (e) => {
                  field.onChange(e.target.value);
                }}
                value={field.value}
                error={!!errors.name?.message}
                helperText={errors.name?.message}
              />
            )}
          />
          <Controller
            name="category"
            control={control}
            rules={requiredValidation}
            render={({ field }) => (
              <FormControl fullWidth size="small" sx={{ mb: 1 }}>
                <InputLabel id="id-category">Категория</InputLabel>
                <Select
                  data-cy="select-category"
                  label="Дистанция"
                  value={field.value}
                  error={!!errors.category?.message}
                  onChange={(event: SelectChangeEvent) => {
                    field.onChange(event.target.value);
                  }}
                >
                  {categories.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!errors.category?.message}>{errors.category?.message}</FormHelperText>
              </FormControl>
            )}
          />
          <Controller
            name="message"
            control={control}
            rules={requiredValidation}
            render={({ field }) => (
              <TextField
                {...field}
                label="Введите текст обращения"
                multiline
                sx={{ mt: 1 }}
                rows={4}
                fullWidth
                variant="outlined"
                error={!!errors.message?.message}
                helperText={errors.message?.message}
              />
            )}
          />
          <LoadingButton
            type="submit"
            loading={isSubmitting}
            fullWidth
            data-cy="create-question-submit"
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
            size="large"
          >
            Отправить
          </LoadingButton>
        </form>
      </Box>
    </Container>
  );
};
