import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  useLazyGetTireBrandsQuery,
  useLazyGetTireModelsQuery,
  useLazyGetTireSeasonsQuery,
  useLazyGetTireSizesQuery,
} from '../../../../redux/services/tasks';
import { IWheel } from '../../../../screens/types';
import { DAMAGE_TYPES, DISK_TYPES, SEASON_CHOICES_MAP, SeasonKey } from '../../consts';
import { IStageTireFitting } from '../../types';

export const AfterEverythingIsDifferent = forwardRef(({ task, stage, tireRadiuses }: IStageTireFitting, ref) => {
  const desiredOrder = [
    'front_left' as 'front_left',
    'front_right' as 'front_right',
    'rear_left' as 'rear_left',
    'rear_right' as 'rear_right',
  ];
  const [isFrontLeftWheelDamaged, setIsFrontLeftWheelDamaged] = React.useState(false);
  const [isFrontRightWheelDamaged, setIsFrontRightWheelDamaged] = React.useState(false);
  const [isRearLeftWheelDamaged, setIsRearLeftWheelDamaged] = React.useState(false);
  const [isRearRightWheelDamaged, setIsRearRightWheelDamaged] = React.useState(false);

  React.useEffect(() => {
    if (task && task.before_tire && task.before_tire.length > 0) {
      setIsFrontLeftWheelDamaged(task.before_tire[0].is_front_left_wheel_damaged === 'yes');
      setIsFrontRightWheelDamaged(task.before_tire[0].is_front_right_wheel_damaged === 'yes');
      setIsRearLeftWheelDamaged(task.before_tire[0].is_rear_left_wheel_damaged === 'yes');
      setIsRearRightWheelDamaged(task.before_tire[0].is_rear_right_wheel_damaged === 'yes');
    }
  }, [task]);

  const wheelsData = React.useMemo(() => {
    let frontLeftDamaged = false;
    let frontRightDamaged = false;
    let rearLeftDamaged = false;
    let rearRightDamaged = false;

    if (task && task.before_tire && task.before_tire.length > 0) {
      frontLeftDamaged = task.before_tire[0].is_front_left_wheel_damaged === 'yes';
      frontRightDamaged = task.before_tire[0].is_front_right_wheel_damaged === 'yes';
      rearLeftDamaged = task.before_tire[0].is_rear_left_wheel_damaged === 'yes';
      rearRightDamaged = task.before_tire[0].is_rear_right_wheel_damaged === 'yes';
    }

    // 1. Берём колёса стадии "after"
    const wheelsAfter =
      task?.wheels
        ?.filter((item) => item.stage === 'after')
        .map((wheel) => ({
          task: task.id,
          brand: wheel.brand,
          model: wheel.model,
          size: wheel.size,
          radius: wheel.radius,
          season: wheel.season,
          disk: wheel.disk,
          stage: stage,
          position: wheel.position,
          reason: wheel.reason || '',
        })) ?? [];

    // 2. Если после нет, берём колёса стадии "before"
    const wheelsBefore =
      wheelsAfter.length > 0
        ? wheelsAfter
        : task?.wheels
            ?.filter((item) => item.stage === 'before')
            .map((wheel) => {
              // Флаги для каждой позиции
              const modifyMap: Record<string, boolean> = {
                front_left: frontLeftDamaged,
                front_right: frontRightDamaged,
                rear_left: rearLeftDamaged,
                rear_right: rearRightDamaged,
              };

              // Если для данной позиции флаг истинный, меняем значения некоторых полей
              const shouldModify = modifyMap[wheel.position] ?? false;

              return {
                task: task.id,
                brand: '',
                model: '',
                size: wheel.size, // оставляем как есть
                radius: wheel.radius, // оставляем как есть
                season: '',
                disk: '', // оставляем как есть
                stage: stage,
                position: wheel.position,
                reason: '',
              };
            }) ?? [];

    // 3. Если и колес нет, создаём шаблон по desiredOrder
    const filteredWheels =
      wheelsBefore.length > 0
        ? wheelsBefore
        : desiredOrder.map((position) => ({
            task: task.id,
            brand: '',
            model: '',
            size: '',
            radius: '',
            season: '',
            disk: '',
            stage: stage,
            position,
            reason: '',
          }));

    // 4. Сортируем итоговый массив согласно desiredOrder.
    return filteredWheels.sort((a, b) => {
      // Если position отсутствует в desiredOrder, возвращаем -1
      const indexA = desiredOrder.indexOf(a.position as any);
      const indexB = desiredOrder.indexOf(b.position as any);
      const safeIndexA = indexA === -1 ? Number.MAX_SAFE_INTEGER : indexA;
      const safeIndexB = indexB === -1 ? Number.MAX_SAFE_INTEGER : indexB;
      return safeIndexA - safeIndexB;
    });
  }, [task, stage]);

  const {
    control,
    trigger,
    getValues,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<{ wheels: IWheel[] }>({
    defaultValues: {
      wheels: wheelsData,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const [tireSizesFrontLeftList, tireSizesFrontLeftListResult] = useLazyGetTireSizesQuery();
  const [tireSizesFrontRightList, tireSizesFrontRightListResult] = useLazyGetTireSizesQuery();
  const [tireSizesRearLeftList, tireSizesRearLeftListResult] = useLazyGetTireSizesQuery();
  const [tireSizesRearRightList, tireSizesRearRightListResult] = useLazyGetTireSizesQuery();
  const [tireBrandsFrontLeftList, tireBrandsFrontLeftListResult] = useLazyGetTireBrandsQuery();
  const [tireBrandsFrontRightList, tireBrandsFrontRightListResult] = useLazyGetTireBrandsQuery();
  const [tireBrandsRearLeftList, tireBrandsRearLeftListResult] = useLazyGetTireBrandsQuery();
  const [tireBrandsRearRightList, tireBrandsRearRightListResult] = useLazyGetTireBrandsQuery();
  const [tireModelsFrontLeftList, tireModelsFrontLeftListResult] = useLazyGetTireModelsQuery();
  const [tireModelsFrontRightList, tireModelsFrontRightListResult] = useLazyGetTireModelsQuery();
  const [tireModelsRearLeftList, tireModelsRearLeftListResult] = useLazyGetTireModelsQuery();
  const [tireModelsRearRightList, tireModelsRearRightListResult] = useLazyGetTireModelsQuery();
  const [tireSeasonsFrontLeftList, tireSeasonsFrontLeftListResult] = useLazyGetTireSeasonsQuery();
  const [tireSeasonsFrontRightList, tireSeasonsFrontRightListResult] = useLazyGetTireSeasonsQuery();
  const [tireSeasonsRearLeftList, tireSeasonsRearLeftListResult] = useLazyGetTireSeasonsQuery();
  const [tireSeasonsRearRightList, tireSeasonsRearRightListResult] = useLazyGetTireSeasonsQuery();

  const firstWheelIndex = React.useMemo(() => {
    if (isFrontLeftWheelDamaged) return 0;
    if (isFrontRightWheelDamaged) return 1;
    if (isRearLeftWheelDamaged) return 2;
    if (isRearRightWheelDamaged) return 3;
    return null;
  }, [isFrontLeftWheelDamaged, isFrontRightWheelDamaged, isRearLeftWheelDamaged, isRearRightWheelDamaged]);

  const firstWheelRadius = watch(firstWheelIndex !== null ? `wheels.${firstWheelIndex}.radius` : 'wheels.4.radius');
  const firstWheelSize = watch(firstWheelIndex !== null ? `wheels.${firstWheelIndex}.size` : 'wheels.4.size');

  React.useEffect(() => {
    if (firstWheelIndex !== null) {
      // Проходим по индексам колес от 0 до 3
      [0, 1, 2, 3].forEach((i) => {
        if (i > firstWheelIndex) {
          // Проверяем, что колесо с индексом i отображается
          if (
            (i === 1 && isFrontRightWheelDamaged) ||
            (i === 2 && isRearLeftWheelDamaged) ||
            (i === 3 && isRearRightWheelDamaged)
          ) {
            setValue(`wheels.${i}.radius`, firstWheelRadius, { shouldValidate: true, shouldDirty: true });
            setValue(`wheels.${i}.size`, firstWheelSize, { shouldValidate: true, shouldDirty: true });
          }
        }
      });
    }
  }, [
    firstWheelIndex,
    firstWheelRadius,
    firstWheelSize,
    isFrontLeftWheelDamaged,
    isFrontRightWheelDamaged,
    isRearLeftWheelDamaged,
    isRearRightWheelDamaged,
    setValue,
  ]);

  React.useEffect(() => {
    if (tireSizesFrontLeftListResult.data?.length === 1) {
      setValue(
        'wheels.0.size',
        tireSizesFrontLeftListResult.data !== undefined ? tireSizesFrontLeftListResult.data[0].size : '',
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
      tireBrandsFrontLeftList({
        company: task.carsharing,
        radius: watch('wheels.0.radius'),
        size: watch('wheels.0.size'),
      });
    }
  }, [tireSizesFrontLeftListResult.data]);

  React.useEffect(() => {
    if (tireSizesFrontRightListResult.data?.length === 1) {
      setValue(
        'wheels.1.size',
        tireSizesFrontRightListResult.data !== undefined ? tireSizesFrontRightListResult.data[0].size : '',
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
      tireBrandsFrontRightList({
        company: task.carsharing,
        radius: watch('wheels.1.radius'),
        size: watch('wheels.1.size'),
      });
    }
  }, [tireSizesFrontRightListResult.data]);

  React.useEffect(() => {
    if (tireSizesRearLeftListResult.data?.length === 1) {
      setValue(
        'wheels.2.size',
        tireSizesRearLeftListResult.data !== undefined ? tireSizesRearLeftListResult.data[0].size : '',
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
      tireBrandsRearLeftList({
        company: task.carsharing,
        radius: watch('wheels.2.radius'),
        size: watch('wheels.2.size'),
      });
    }
  }, [tireSizesRearLeftListResult.data]);

  React.useEffect(() => {
    if (tireSizesRearRightListResult.data?.length === 1) {
      setValue(
        'wheels.3.size',
        tireSizesRearRightListResult.data !== undefined ? tireSizesRearRightListResult.data[0].size : '',
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
      tireBrandsRearRightList({
        company: task.carsharing,
        radius: watch('wheels.3.radius'),
        size: watch('wheels.3.size'),
      });
    }
  }, [tireSizesRearRightListResult.data]);

  React.useEffect(() => {
    if (tireBrandsFrontLeftListResult.data?.length === 1) {
      setValue(
        'wheels.0.brand',
        tireBrandsFrontLeftListResult.data !== undefined ? tireBrandsFrontLeftListResult.data[0].brand : '',
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
      tireModelsFrontLeftList({
        company: task.carsharing,
        radius: watch('wheels.0.radius'),
        size: watch('wheels.0.size'),
        brand: watch('wheels.0.brand'),
      });
    }
  }, [tireBrandsFrontLeftListResult.data]);

  React.useEffect(() => {
    if (tireBrandsFrontRightListResult.data?.length === 1) {
      setValue(
        'wheels.1.brand',
        tireBrandsFrontRightListResult.data !== undefined ? tireBrandsFrontRightListResult.data[0].brand : '',
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
      tireModelsFrontRightList({
        company: task.carsharing,
        radius: watch('wheels.1.radius'),
        size: watch('wheels.1.size'),
        brand: watch('wheels.1.brand'),
      });
    }
  }, [tireBrandsFrontRightListResult.data]);

  React.useEffect(() => {
    if (tireBrandsRearLeftListResult.data?.length === 1) {
      setValue(
        'wheels.2.brand',
        tireBrandsRearLeftListResult.data !== undefined ? tireBrandsRearLeftListResult.data[0].brand : '',
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
      tireModelsRearLeftList({
        company: task.carsharing,
        radius: watch('wheels.2.radius'),
        size: watch('wheels.2.size'),
        brand: watch('wheels.2.brand'),
      });
    }
  }, [tireBrandsRearLeftListResult.data]);

  React.useEffect(() => {
    if (tireBrandsRearRightListResult.data?.length === 1) {
      setValue(
        'wheels.3.brand',
        tireBrandsRearRightListResult.data !== undefined ? tireBrandsRearRightListResult.data[0].brand : '',
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
      tireModelsRearRightList({
        company: task.carsharing,
        radius: watch('wheels.3.radius'),
        size: watch('wheels.3.size'),
        brand: watch('wheels.3.brand'),
      });
    }
  }, [tireBrandsRearRightListResult.data]);

  React.useEffect(() => {
    if (tireModelsFrontLeftListResult.data?.length === 1) {
      setValue(
        'wheels.0.model',
        tireModelsFrontLeftListResult.data !== undefined ? tireModelsFrontLeftListResult.data[0].model : '',
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
      tireSeasonsFrontLeftList({
        company: task.carsharing,
        radius: watch('wheels.0.radius'),
        size: watch('wheels.0.size'),
        brand: watch('wheels.0.brand'),
        model: watch('wheels.0.model'),
      });
    }
  }, [tireModelsFrontLeftListResult.data]);

  React.useEffect(() => {
    if (tireModelsFrontRightListResult.data?.length === 1) {
      setValue(
        'wheels.1.model',
        tireModelsFrontRightListResult.data !== undefined ? tireModelsFrontRightListResult.data[0].model : '',
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
      tireSeasonsFrontRightList({
        company: task.carsharing,
        radius: watch('wheels.1.radius'),
        size: watch('wheels.1.size'),
        brand: watch('wheels.1.brand'),
        model: watch('wheels.1.model'),
      });
    }
  }, [tireModelsFrontRightListResult.data]);

  React.useEffect(() => {
    if (tireModelsRearLeftListResult.data?.length === 1) {
      setValue(
        'wheels.2.model',
        tireModelsRearLeftListResult.data !== undefined ? tireModelsRearLeftListResult.data[0].model : '',
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
      tireSeasonsRearLeftList({
        company: task.carsharing,
        radius: watch('wheels.2.radius'),
        size: watch('wheels.2.size'),
        brand: watch('wheels.2.brand'),
        model: watch('wheels.2.model'),
      });
    }
  }, [tireModelsRearLeftListResult.data]);

  React.useEffect(() => {
    if (tireModelsRearRightListResult.data?.length === 1) {
      setValue(
        'wheels.3.model',
        tireModelsRearRightListResult.data !== undefined ? tireModelsRearRightListResult.data[0].model : '',
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
      tireSeasonsRearRightList({
        company: task.carsharing,
        radius: watch('wheels.3.radius'),
        size: watch('wheels.3.size'),
        brand: watch('wheels.3.brand'),
        model: watch('wheels.3.model'),
      });
    }
  }, [tireModelsRearRightListResult.data]);

  React.useEffect(() => {
    if (tireSeasonsFrontLeftListResult.data?.length === 1) {
      setValue(
        'wheels.0.season',
        tireSeasonsFrontLeftListResult.data !== undefined ? tireSeasonsFrontLeftListResult.data[0].season : '',
        { shouldValidate: true, shouldDirty: true },
      );
    }
  }, [tireSeasonsFrontLeftListResult.data]);

  React.useEffect(() => {
    if (tireSeasonsFrontRightListResult.data?.length === 1) {
      setValue(
        'wheels.1.season',
        tireSeasonsFrontRightListResult.data !== undefined ? tireSeasonsFrontRightListResult.data[0].season : '',
        { shouldValidate: true, shouldDirty: true },
      );
    }
  }, [tireSeasonsFrontRightListResult.data]);

  React.useEffect(() => {
    if (tireSeasonsRearLeftListResult.data?.length === 1) {
      setValue(
        'wheels.2.season',
        tireSeasonsRearLeftListResult.data !== undefined ? tireSeasonsRearLeftListResult.data[0].season : '',
        { shouldValidate: true, shouldDirty: true },
      );
    }
  }, [tireSeasonsRearLeftListResult.data]);

  React.useEffect(() => {
    if (tireSeasonsRearRightListResult.data?.length === 1) {
      setValue(
        'wheels.3.season',
        tireSeasonsRearRightListResult.data !== undefined ? tireSeasonsRearRightListResult.data[0].season : '',
        { shouldValidate: true, shouldDirty: true },
      );
    }
  }, [tireSeasonsRearRightListResult.data]);

  React.useEffect(() => {
    if (watch('wheels.0.radius')) {
      tireSizesFrontLeftList({
        company: task.carsharing,
        radius: watch('wheels.0.radius'),
      });
    }
  }, [watch('wheels.0.radius'), tireSizesFrontLeftList]);

  React.useEffect(() => {
    if (watch('wheels.1.radius')) {
      tireSizesFrontRightList({
        company: task.carsharing,
        radius: watch('wheels.1.radius'),
      });
    }
  }, [watch('wheels.1.radius'), tireSizesFrontRightList]);

  React.useEffect(() => {
    if (watch('wheels.2.radius')) {
      tireSizesRearLeftList({
        company: task.carsharing,
        radius: watch('wheels.2.radius'),
      });
    }
  }, [watch('wheels.2.radius'), tireSizesRearLeftList]);

  React.useEffect(() => {
    if (watch('wheels.3.radius')) {
      tireSizesRearRightList({
        company: task.carsharing,
        radius: watch('wheels.3.radius'),
      });
    }
  }, [watch('wheels.3.radius'), tireSizesRearRightList]);

  React.useEffect(() => {
    if (watch('wheels.0.radius') && watch('wheels.0.size')) {
      tireBrandsFrontLeftList({
        company: task.carsharing,
        radius: watch('wheels.0.radius'),
        size: watch('wheels.0.size'),
      });
    }
  }, [watch('wheels.0.radius'), watch('wheels.0.size'), tireBrandsFrontLeftList]);

  React.useEffect(() => {
    if (watch('wheels.1.radius') && watch('wheels.1.size')) {
      tireBrandsFrontRightList({
        company: task.carsharing,
        radius: watch('wheels.1.radius'),
        size: watch('wheels.1.size'),
      });
    }
  }, [watch('wheels.1.radius'), watch('wheels.1.size'), tireBrandsFrontRightList]);

  React.useEffect(() => {
    if (watch('wheels.2.radius') && watch('wheels.2.size')) {
      tireBrandsRearLeftList({
        company: task.carsharing,
        radius: watch('wheels.2.radius'),
        size: watch('wheels.2.size'),
      });
    }
  }, [watch('wheels.2.radius'), watch('wheels.2.size'), tireBrandsRearLeftList]);

  React.useEffect(() => {
    if (watch('wheels.3.radius') && watch('wheels.3.size')) {
      tireBrandsRearRightList({
        company: task.carsharing,
        radius: watch('wheels.3.radius'),
        size: watch('wheels.3.size'),
      });
    }
  }, [watch('wheels.3.radius'), watch('wheels.3.size'), tireBrandsRearRightList]);

  React.useEffect(() => {
    if (watch('wheels.0.radius') && watch('wheels.0.size') && watch('wheels.0.brand')) {
      tireModelsFrontLeftList({
        company: task.carsharing,
        radius: watch('wheels.0.radius'),
        size: watch('wheels.0.size'),
        brand: watch('wheels.0.brand'),
      });
    }
  }, [watch('wheels.0.radius'), watch('wheels.0.size'), watch('wheels.0.brand'), tireModelsFrontLeftList]);

  React.useEffect(() => {
    if (watch('wheels.1.radius') && watch('wheels.1.size') && watch('wheels.1.brand')) {
      tireModelsFrontRightList({
        company: task.carsharing,
        radius: watch('wheels.1.radius'),
        size: watch('wheels.1.size'),
        brand: watch('wheels.1.brand'),
      });
    }
  }, [watch('wheels.1.radius'), watch('wheels.1.size'), watch('wheels.1.brand'), tireModelsFrontRightList]);

  React.useEffect(() => {
    if (watch('wheels.2.radius') && watch('wheels.2.size') && watch('wheels.2.brand')) {
      tireModelsRearLeftList({
        company: task.carsharing,
        radius: watch('wheels.2.radius'),
        size: watch('wheels.2.size'),
        brand: watch('wheels.2.brand'),
      });
    }
  }, [watch('wheels.2.radius'), watch('wheels.2.size'), watch('wheels.2.brand'), tireModelsRearLeftList]);

  React.useEffect(() => {
    if (watch('wheels.3.radius') && watch('wheels.3.size') && watch('wheels.3.brand')) {
      tireModelsRearRightList({
        company: task.carsharing,
        radius: watch('wheels.3.radius'),
        size: watch('wheels.3.size'),
        brand: watch('wheels.3.brand'),
      });
    }
  }, [watch('wheels.3.radius'), watch('wheels.3.size'), watch('wheels.3.brand'), tireModelsRearRightList]);

  React.useEffect(() => {
    if (watch('wheels.0.radius') && watch('wheels.0.size') && watch('wheels.0.brand') && watch('wheels.0.model')) {
      tireSeasonsFrontLeftList({
        company: task.carsharing,
        radius: watch('wheels.0.radius'),
        size: watch('wheels.0.size'),
        brand: watch('wheels.0.brand'),
        model: watch('wheels.0.model'),
      });
    }
  }, [
    watch('wheels.0.radius'),
    watch('wheels.0.size'),
    watch('wheels.0.brand'),
    watch('wheels.0.model'),
    tireSeasonsFrontLeftList,
  ]);

  React.useEffect(() => {
    if (watch('wheels.1.radius') && watch('wheels.1.size') && watch('wheels.1.brand') && watch('wheels.1.model')) {
      tireSeasonsFrontRightList({
        company: task.carsharing,
        radius: watch('wheels.1.radius'),
        size: watch('wheels.1.size'),
        brand: watch('wheels.1.brand'),
        model: watch('wheels.1.model'),
      });
    }
  }, [
    watch('wheels.1.radius'),
    watch('wheels.1.size'),
    watch('wheels.1.brand'),
    watch('wheels.1.model'),
    tireSeasonsFrontRightList,
  ]);

  React.useEffect(() => {
    if (watch('wheels.2.radius') && watch('wheels.2.size') && watch('wheels.2.brand') && watch('wheels.2.model')) {
      tireSeasonsRearLeftList({
        company: task.carsharing,
        radius: watch('wheels.2.radius'),
        size: watch('wheels.2.size'),
        brand: watch('wheels.2.brand'),
        model: watch('wheels.2.model'),
      });
    }
  }, [
    watch('wheels.2.radius'),
    watch('wheels.2.size'),
    watch('wheels.2.brand'),
    watch('wheels.2.model'),
    tireSeasonsRearLeftList,
  ]);

  React.useEffect(() => {
    if (watch('wheels.3.radius') && watch('wheels.3.size') && watch('wheels.3.brand') && watch('wheels.3.model')) {
      tireSeasonsRearRightList({
        company: task.carsharing,
        radius: watch('wheels.3.radius'),
        size: watch('wheels.3.size'),
        brand: watch('wheels.3.brand'),
        model: watch('wheels.3.model'),
      });
    }
  }, [
    watch('wheels.3.radius'),
    watch('wheels.3.size'),
    watch('wheels.3.brand'),
    watch('wheels.3.model'),
    tireSeasonsRearRightList,
  ]);

  // Делаем submit доступным для родителя
  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      // Если форма изменилась, запускаем валидацию
      const valid = await trigger();
      if (!valid) {
        return null;
      }
      return getValues('wheels');
    },
  }));

  return (
    <Box>
      {isFrontLeftWheelDamaged ||
        (isFrontRightWheelDamaged && (
          <>
            <Typography sx={{ textAlign: 'center', marginBottom: 1 }}>Переднее левое</Typography>
            <Controller
              name="wheels.0.radius"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  onChange={(event, newValue) => {
                    if (newValue !== watch('wheels.0.radius')) {
                      const fields: Array<keyof IWheel> = ['size', 'brand', 'model', 'season'];
                      [0, 1, 2, 3].forEach((index) =>
                        setValue(`wheels.${index}.radius`, newValue, { shouldValidate: true, shouldDirty: true }),
                      );
                      fields.forEach((field) =>
                        [0, 1, 2, 3].forEach((index) => setValue(`wheels.${index}.${field}` as const, '')),
                      );
                    }
                  }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Радиус"
                      error={!!errors.wheels?.[0]?.radius}
                      helperText={errors.wheels?.[0]?.radius?.message}
                    />
                  )}
                  options={tireRadiuses !== undefined ? tireRadiuses.map((option) => option.radius) : []}
                />
              )}
            />
            <Controller
              name="wheels.0.size"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  value={field.value || ''}
                  disabled={tireSizesFrontLeftListResult.isFetching || tireSizesFrontLeftListResult.isLoading}
                  onChange={(event, newValue) => {
                    if (newValue !== watch('wheels.0.size')) {
                      const fields: Array<keyof IWheel> = ['brand', 'model', 'season'];
                      [0, 1, 2, 3].forEach((index) =>
                        setValue(`wheels.${index}.size`, newValue, { shouldValidate: true, shouldDirty: true }),
                      );
                      fields.forEach((field) =>
                        [0, 1, 2, 3].forEach((index) => setValue(`wheels.${index}.${field}` as const, '')),
                      );
                    }
                  }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Размер"
                      error={!!errors.wheels?.[0]?.size}
                      helperText={errors.wheels?.[0]?.size?.message}
                    />
                  )}
                  options={
                    tireSizesFrontLeftListResult.data !== undefined
                      ? tireSizesFrontLeftListResult.data.map((option) => option.size)
                      : []
                  }
                />
              )}
            />
            <Controller
              name="wheels.0.brand"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  value={field.value || ''}
                  disabled={tireBrandsFrontLeftListResult.isFetching || tireBrandsFrontLeftListResult.isLoading}
                  onChange={(event, newValue) => {
                    if (newValue !== watch('wheels.0.brand')) {
                      const fields: Array<keyof IWheel> = ['model', 'season'];
                      setValue('wheels.0.brand', newValue, { shouldValidate: true, shouldDirty: true });
                      fields.forEach((field) => setValue(`wheels.0.${field}` as const, ''));
                    }
                  }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Марка"
                      error={!!errors.wheels?.[0]?.brand}
                      helperText={errors.wheels?.[0]?.brand?.message}
                    />
                  )}
                  options={
                    tireBrandsFrontLeftListResult.data !== undefined
                      ? tireBrandsFrontLeftListResult.data.map((option) => option.brand)
                      : []
                  }
                />
              )}
            />
            <Controller
              name="wheels.0.model"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  value={field.value || ''}
                  disabled={tireModelsFrontLeftListResult.isFetching || tireModelsFrontLeftListResult.isLoading}
                  onChange={(event, newValue) => {
                    if (newValue !== watch('wheels.0.model')) {
                      const fields: Array<keyof IWheel> = ['season'];
                      setValue('wheels.0.model', newValue, { shouldValidate: true, shouldDirty: true });
                      fields.forEach((field) => setValue(`wheels.0.${field}` as const, ''));
                    }
                  }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Модель"
                      error={!!errors.wheels?.[0]?.model}
                      helperText={errors.wheels?.[0]?.model?.message}
                    />
                  )}
                  options={
                    tireModelsFrontLeftListResult.data !== undefined
                      ? tireModelsFrontLeftListResult.data.map((option) => option.model)
                      : []
                  }
                />
              )}
            />
            <Controller
              name="wheels.0.season"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  value={field.value || ''}
                  disabled={tireSeasonsFrontLeftListResult.isFetching || tireSeasonsFrontLeftListResult.isLoading}
                  onChange={(event, newValue) => {
                    if (newValue !== null && newValue !== watch('wheels.0.season')) {
                      setValue('wheels.0.season', newValue, { shouldValidate: true, shouldDirty: true });
                      setValue('wheels.0.disk' as const, '');
                    }
                  }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Сезонность"
                      error={!!errors.wheels?.[0]?.season}
                      helperText={errors.wheels?.[0]?.season?.message}
                    />
                  )}
                  options={
                    tireSeasonsFrontLeftListResult.data !== undefined
                      ? tireSeasonsFrontLeftListResult.data.map((option) => option.season)
                      : []
                  }
                  getOptionLabel={(option) => SEASON_CHOICES_MAP[option as SeasonKey] || option} // Преобразование в человекочитаемый формат
                />
              )}
            />
            <Controller
              name="wheels.0.disk"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  onChange={(event, newValue) => {
                    if (newValue.id !== field.value) {
                      setValue('wheels.0.disk', newValue.id, { shouldValidate: true, shouldDirty: true });
                    }
                  }}
                  value={DISK_TYPES.find((option) => option.id === field.value) || { id: '', label: '' }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Тип диска"
                      error={!!errors.wheels?.[0]?.disk} // Отображение ошибки
                      helperText={errors.wheels?.[0]?.disk?.message}
                    />
                  )}
                  options={DISK_TYPES}
                />
              )}
            />
            {isFrontLeftWheelDamaged && (
              <Controller
                name="wheels.0.reason"
                control={control}
                rules={{ required: 'Обязательно для заполнения' }}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    size="small"
                    fullWidth
                    disableClearable
                    sx={{ marginBottom: 2 }}
                    value={field.value || ''}
                    onChange={(event, newValue) => {
                      if (newValue !== watch('wheels.0.reason')) {
                        field.onChange(newValue);
                      }
                    }}
                    noOptionsText="Не найдено"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Причина поломки"
                        error={!!errors.wheels?.[0]?.reason} // Отображение ошибки
                        helperText={errors.wheels?.[0]?.reason?.message}
                      />
                    )}
                    options={DAMAGE_TYPES}
                  />
                )}
              />
            )}
            <Typography sx={{ textAlign: 'center', marginBottom: 1 }}>Переднее правое</Typography>
            <Controller
              name="wheels.1.radius"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  onChange={(event, newValue) => {
                    if (newValue !== watch('wheels.1.radius')) {
                      const fields: Array<keyof IWheel> = ['size', 'brand', 'model', 'season'];
                      setValue('wheels.1.radius', newValue, { shouldValidate: true, shouldDirty: true });
                      fields.forEach((field) => setValue(`wheels.1.${field}` as const, ''));
                    }
                  }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Радиус"
                      error={!!errors.wheels?.[1]?.radius}
                      helperText={errors.wheels?.[1]?.radius?.message}
                    />
                  )}
                  options={tireRadiuses !== undefined ? tireRadiuses.map((option) => option.radius) : []}
                />
              )}
            />
            <Controller
              name="wheels.1.size"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  value={field.value || ''}
                  disabled={tireSizesFrontRightListResult.isFetching || tireSizesFrontRightListResult.isLoading}
                  onChange={(event, newValue) => {
                    if (newValue !== watch('wheels.1.size')) {
                      const fields: Array<keyof IWheel> = ['brand', 'model', 'season'];
                      setValue('wheels.1.size', newValue, { shouldValidate: true, shouldDirty: true });
                      fields.forEach((field) => setValue(`wheels.1.${field}` as const, ''));
                    }
                  }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Размер"
                      error={!!errors.wheels?.[1]?.size}
                      helperText={errors.wheels?.[1]?.size?.message}
                    />
                  )}
                  options={
                    tireSizesFrontRightListResult.data !== undefined
                      ? tireSizesFrontRightListResult.data.map((option) => option.size)
                      : []
                  }
                />
              )}
            />
            <Controller
              name="wheels.1.brand"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  value={field.value || ''}
                  disabled={tireBrandsFrontRightListResult.isFetching || tireBrandsFrontRightListResult.isLoading}
                  onChange={(event, newValue) => {
                    if (newValue !== watch('wheels.1.brand')) {
                      const fields: Array<keyof IWheel> = ['model', 'season'];
                      setValue('wheels.1.brand', newValue, { shouldValidate: true, shouldDirty: true });
                      fields.forEach((field) => setValue(`wheels.1.${field}` as const, ''));
                    }
                  }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Марка"
                      error={!!errors.wheels?.[1]?.brand}
                      helperText={errors.wheels?.[1]?.brand?.message}
                    />
                  )}
                  options={
                    tireBrandsFrontRightListResult.data !== undefined
                      ? tireBrandsFrontRightListResult.data.map((option) => option.brand)
                      : []
                  }
                />
              )}
            />
            <Controller
              name="wheels.1.model"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  value={field.value || ''}
                  disabled={tireModelsFrontRightListResult.isFetching || tireModelsFrontRightListResult.isLoading}
                  onChange={(event, newValue) => {
                    if (newValue !== watch('wheels.1.model')) {
                      const fields: Array<keyof IWheel> = ['season'];
                      setValue('wheels.1.model', newValue, { shouldValidate: true, shouldDirty: true });
                      fields.forEach((field) => setValue(`wheels.1.${field}` as const, ''));
                    }
                  }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Модель"
                      error={!!errors.wheels?.[1]?.model}
                      helperText={errors.wheels?.[1]?.model?.message}
                    />
                  )}
                  options={
                    tireModelsFrontRightListResult.data !== undefined
                      ? tireModelsFrontRightListResult.data.map((option) => option.model)
                      : []
                  }
                />
              )}
            />
            <Controller
              name="wheels.1.season"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  value={field.value || ''}
                  disabled={tireSeasonsFrontRightListResult.isFetching || tireSeasonsFrontRightListResult.isLoading}
                  onChange={(event, newValue) => {
                    if (newValue !== null && newValue !== watch('wheels.1.season')) {
                      setValue('wheels.1.season', newValue, { shouldValidate: true, shouldDirty: true });
                      setValue('wheels.1.disk' as const, '');
                    }
                  }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Сезонность"
                      error={!!errors.wheels?.[1]?.season}
                      helperText={errors.wheels?.[1]?.season?.message}
                    />
                  )}
                  options={
                    tireSeasonsFrontRightListResult.data !== undefined
                      ? tireSeasonsFrontRightListResult.data.map((option) => option.season)
                      : []
                  }
                  getOptionLabel={(option) => SEASON_CHOICES_MAP[option as SeasonKey] || option} // Преобразование в человекочитаемый формат
                />
              )}
            />
            <Controller
              name="wheels.1.disk"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  onChange={(event, newValue) => {
                    if (newValue.id !== field.value) {
                      setValue('wheels.1.disk', newValue.id, { shouldValidate: true, shouldDirty: true });
                    }
                  }}
                  value={DISK_TYPES.find((option) => option.id === field.value) || { id: '', label: '' }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Тип диска"
                      error={!!errors.wheels?.[1]?.disk} // Отображение ошибки
                      helperText={errors.wheels?.[1]?.disk?.message}
                    />
                  )}
                  options={DISK_TYPES}
                />
              )}
            />
            {isFrontRightWheelDamaged && (
              <Controller
                name="wheels.1.reason"
                control={control}
                rules={{ required: 'Обязательно для заполнения' }}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    size="small"
                    fullWidth
                    disableClearable
                    sx={{ marginBottom: 2 }}
                    value={field.value || ''}
                    onChange={(event, newValue) => {
                      if (newValue !== watch('wheels.1.reason')) {
                        field.onChange(newValue);
                      }
                    }}
                    noOptionsText="Не найдено"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Причина поломки"
                        error={!!errors.wheels?.[1]?.reason} // Отображение ошибки
                        helperText={errors.wheels?.[1]?.reason?.message}
                      />
                    )}
                    options={DAMAGE_TYPES}
                  />
                )}
              />
            )}
          </>
        ))}
      {isRearLeftWheelDamaged ||
        (isRearRightWheelDamaged && (
          <>
            <Typography sx={{ textAlign: 'center', marginBottom: 1 }}>Заднее левое</Typography>
            <Controller
              name="wheels.2.radius"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  onChange={(event, newValue) => {
                    if (newValue !== watch('wheels.2.radius')) {
                      const fields: Array<keyof IWheel> = ['size', 'brand', 'model', 'season'];
                      setValue('wheels.2.radius', newValue, { shouldValidate: true, shouldDirty: true });
                      fields.forEach((field) => setValue(`wheels.2.${field}` as const, ''));
                    }
                  }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Радиус"
                      error={!!errors.wheels?.[2]?.radius}
                      helperText={errors.wheels?.[2]?.radius?.message}
                    />
                  )}
                  options={tireRadiuses !== undefined ? tireRadiuses.map((option) => option.radius) : []}
                />
              )}
            />
            <Controller
              name="wheels.2.size"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  value={field.value || ''}
                  disabled={tireSizesRearLeftListResult.isFetching || tireSizesRearLeftListResult.isLoading}
                  onChange={(event, newValue) => {
                    if (newValue !== watch('wheels.2.size')) {
                      const fields: Array<keyof IWheel> = ['brand', 'model', 'season'];
                      setValue('wheels.2.size', newValue, { shouldValidate: true, shouldDirty: true });
                      fields.forEach((field) => setValue(`wheels.2.${field}` as const, ''));
                    }
                  }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Размер"
                      error={!!errors.wheels?.[2]?.size}
                      helperText={errors.wheels?.[2]?.size?.message}
                    />
                  )}
                  options={
                    tireSizesRearLeftListResult.data !== undefined
                      ? tireSizesRearLeftListResult.data.map((option) => option.size)
                      : []
                  }
                />
              )}
            />
            <Controller
              name="wheels.2.brand"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  value={field.value || ''}
                  disabled={tireBrandsRearLeftListResult.isFetching || tireBrandsRearLeftListResult.isLoading}
                  onChange={(event, newValue) => {
                    if (newValue !== watch('wheels.2.brand')) {
                      const fields: Array<keyof IWheel> = ['model', 'season'];
                      setValue('wheels.2.brand', newValue, { shouldValidate: true, shouldDirty: true });
                      fields.forEach((field) => setValue(`wheels.2.${field}` as const, ''));
                    }
                  }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Марка"
                      error={!!errors.wheels?.[2]?.brand}
                      helperText={errors.wheels?.[2]?.brand?.message}
                    />
                  )}
                  options={
                    tireBrandsRearLeftListResult.data !== undefined
                      ? tireBrandsRearLeftListResult.data.map((option) => option.brand)
                      : []
                  }
                />
              )}
            />
            <Controller
              name="wheels.2.model"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  value={field.value || ''}
                  disabled={tireModelsRearLeftListResult.isFetching || tireModelsRearLeftListResult.isLoading}
                  onChange={(event, newValue) => {
                    if (newValue !== watch('wheels.2.model')) {
                      const fields: Array<keyof IWheel> = ['season'];
                      setValue('wheels.2.model', newValue, { shouldValidate: true, shouldDirty: true });
                      fields.forEach((field) => setValue(`wheels.2.${field}` as const, ''));
                    }
                  }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Модель"
                      error={!!errors.wheels?.[2]?.model}
                      helperText={errors.wheels?.[2]?.model?.message}
                    />
                  )}
                  options={
                    tireModelsRearLeftListResult.data !== undefined
                      ? tireModelsRearLeftListResult.data.map((option) => option.model)
                      : []
                  }
                />
              )}
            />
            <Controller
              name="wheels.2.season"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  value={field.value || ''}
                  disabled={tireSeasonsRearLeftListResult.isFetching || tireSeasonsRearLeftListResult.isLoading}
                  onChange={(event, newValue) => {
                    if (newValue !== null && newValue !== watch('wheels.2.season')) {
                      setValue('wheels.2.season', newValue, { shouldValidate: true, shouldDirty: true });
                      setValue('wheels.2.disk' as const, '');
                    }
                  }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Сезонность"
                      error={!!errors.wheels?.[2]?.season}
                      helperText={errors.wheels?.[2]?.season?.message}
                    />
                  )}
                  options={
                    tireSeasonsRearLeftListResult.data !== undefined
                      ? tireSeasonsRearLeftListResult.data.map((option) => option.season)
                      : []
                  }
                  getOptionLabel={(option) => SEASON_CHOICES_MAP[option as SeasonKey] || option} // Преобразование в человекочитаемый формат
                />
              )}
            />
            <Controller
              name="wheels.2.disk"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  onChange={(event, newValue) => {
                    if (newValue.id !== field.value) {
                      setValue('wheels.2.disk', newValue.id, { shouldValidate: true, shouldDirty: true });
                    }
                  }}
                  value={DISK_TYPES.find((option) => option.id === field.value) || { id: '', label: '' }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Тип диска"
                      error={!!errors.wheels?.[2]?.disk} // Отображение ошибки
                      helperText={errors.wheels?.[2]?.disk?.message}
                    />
                  )}
                  options={DISK_TYPES}
                />
              )}
            />
            {isRearLeftWheelDamaged && (
              <Controller
                name="wheels.2.reason"
                control={control}
                rules={{ required: 'Обязательно для заполнения' }}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    size="small"
                    fullWidth
                    disableClearable
                    sx={{ marginBottom: 2 }}
                    value={field.value || ''}
                    onChange={(event, newValue) => {
                      if (newValue !== watch('wheels.2.reason')) {
                        field.onChange(newValue);
                      }
                    }}
                    noOptionsText="Не найдено"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Причина поломки"
                        error={!!errors.wheels?.[2]?.reason} // Отображение ошибки
                        helperText={errors.wheels?.[2]?.reason?.message}
                      />
                    )}
                    options={DAMAGE_TYPES}
                  />
                )}
              />
            )}

            <Typography sx={{ textAlign: 'center', marginBottom: 1 }}>Заднее правое</Typography>
            <Controller
              name="wheels.3.radius"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  onChange={(event, newValue) => {
                    if (newValue !== watch('wheels.3.radius')) {
                      const fields: Array<keyof IWheel> = ['size', 'brand', 'model', 'season'];
                      setValue('wheels.3.radius', newValue, { shouldValidate: true, shouldDirty: true });
                      fields.forEach((field) => setValue(`wheels.3.${field}` as const, ''));
                    }
                  }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Радиус"
                      error={!!errors.wheels?.[3]?.radius}
                      helperText={errors.wheels?.[3]?.radius?.message}
                    />
                  )}
                  options={tireRadiuses !== undefined ? tireRadiuses.map((option) => option.radius) : []}
                />
              )}
            />
            <Controller
              name="wheels.3.size"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  value={field.value || ''}
                  disabled={tireSizesRearRightListResult.isFetching || tireSizesRearRightListResult.isLoading}
                  onChange={(event, newValue) => {
                    if (newValue !== watch('wheels.3.size')) {
                      const fields: Array<keyof IWheel> = ['brand', 'model', 'season'];
                      setValue('wheels.3.size', newValue, { shouldValidate: true, shouldDirty: true });
                      fields.forEach((field) => setValue(`wheels.3.${field}` as const, ''));
                    }
                  }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Размер"
                      error={!!errors.wheels?.[3]?.size}
                      helperText={errors.wheels?.[3]?.size?.message}
                    />
                  )}
                  options={
                    tireSizesRearRightListResult.data !== undefined
                      ? tireSizesRearRightListResult.data.map((option) => option.size)
                      : []
                  }
                />
              )}
            />
            <Controller
              name="wheels.3.brand"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  value={field.value || ''}
                  disabled={tireBrandsRearRightListResult.isFetching || tireBrandsRearRightListResult.isLoading}
                  onChange={(event, newValue) => {
                    if (newValue !== watch('wheels.3.brand')) {
                      const fields: Array<keyof IWheel> = ['model', 'season'];
                      setValue('wheels.3.brand', newValue, { shouldValidate: true, shouldDirty: true });
                      fields.forEach((field) => setValue(`wheels.3.${field}` as const, ''));
                    }
                  }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Марка"
                      error={!!errors.wheels?.[3]?.brand}
                      helperText={errors.wheels?.[3]?.brand?.message}
                    />
                  )}
                  options={
                    tireBrandsRearRightListResult.data !== undefined
                      ? tireBrandsRearRightListResult.data.map((option) => option.brand)
                      : []
                  }
                />
              )}
            />
            <Controller
              name="wheels.3.model"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  value={field.value || ''}
                  disabled={tireModelsRearRightListResult.isFetching || tireModelsRearRightListResult.isLoading}
                  onChange={(event, newValue) => {
                    if (newValue !== watch('wheels.3.model')) {
                      const fields: Array<keyof IWheel> = ['season'];
                      setValue('wheels.3.model', newValue, { shouldValidate: true, shouldDirty: true });
                      fields.forEach((field) => setValue(`wheels.3.${field}` as const, ''));
                    }
                  }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Модель"
                      error={!!errors.wheels?.[3]?.model}
                      helperText={errors.wheels?.[3]?.model?.message}
                    />
                  )}
                  options={
                    tireModelsRearRightListResult.data !== undefined
                      ? tireModelsRearRightListResult.data.map((option) => option.model)
                      : []
                  }
                />
              )}
            />
            <Controller
              name="wheels.3.season"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  value={field.value || ''}
                  disabled={tireSeasonsRearRightListResult.isFetching || tireSeasonsRearRightListResult.isLoading}
                  onChange={(event, newValue) => {
                    if (newValue !== null && newValue !== watch('wheels.3.season')) {
                      setValue('wheels.3.season', newValue, { shouldValidate: true, shouldDirty: true });
                      setValue('wheels.3.disk' as const, '');
                    }
                  }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option === value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Сезонность"
                      error={!!errors.wheels?.[3]?.season}
                      helperText={errors.wheels?.[3]?.season?.message}
                    />
                  )}
                  options={
                    tireSeasonsRearRightListResult.data !== undefined
                      ? tireSeasonsRearRightListResult.data.map((option) => option.season)
                      : []
                  }
                  getOptionLabel={(option) => SEASON_CHOICES_MAP[option as SeasonKey] || option} // Преобразование в человекочитаемый формат
                />
              )}
            />
            <Controller
              name="wheels.3.disk"
              control={control}
              rules={{ required: 'Обязательно для заполнения' }}
              render={({ field, fieldState: { error } }) => (
                <Autocomplete
                  {...field}
                  size="small"
                  fullWidth
                  disableClearable
                  sx={{ marginBottom: 2 }}
                  onChange={(event, newValue) => {
                    if (newValue.id !== field.value) {
                      setValue('wheels.3.disk', newValue.id, { shouldValidate: true, shouldDirty: true });
                    }
                  }}
                  value={DISK_TYPES.find((option) => option.id === field.value) || { id: '', label: '' }}
                  noOptionsText="Не найдено"
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Тип диска"
                      error={!!errors.wheels?.[3]?.disk} // Отображение ошибки
                      helperText={errors.wheels?.[3]?.disk?.message}
                    />
                  )}
                  options={DISK_TYPES}
                />
              )}
            />
            {isRearRightWheelDamaged && (
              <Controller
                name="wheels.3.reason"
                control={control}
                rules={{ required: 'Обязательно для заполнения' }}
                render={({ field, fieldState: { error } }) => (
                  <Autocomplete
                    {...field}
                    size="small"
                    fullWidth
                    disableClearable
                    sx={{ marginBottom: 2 }}
                    value={field.value || ''}
                    onChange={(event, newValue) => {
                      if (newValue !== watch('wheels.3.reason')) {
                        field.onChange(newValue);
                      }
                    }}
                    noOptionsText="Не найдено"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Причина поломки"
                        error={!!errors.wheels?.[3]?.reason} // Отображение ошибки
                        helperText={errors.wheels?.[3]?.reason?.message}
                      />
                    )}
                    options={DAMAGE_TYPES}
                  />
                )}
              />
            )}
          </>
        ))}
    </Box>
  );
});
