import { Autocomplete, Box, Fab, TextField } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LuRefreshCcw, LuUploadCloud } from 'react-icons/lu';
import { IBeforeAndAfterTireFitting, IPostWithdrawalFromTheImpoundLot } from '../../screens/types';
import { usePostAfterWithdrawalFromTheImpoundLotCreateMutation } from '../../redux/services/tasks';
import { toast } from 'react-toastify';
import {
  evacuationReasonsTypes,
  fixedAmounts,
  iniciatorsTypes,
  locationOptions,
  optionsAreTheWheelsAndTiresTheSameBrandAndType,
  paymentMethodsTwoTypes,
} from './consts';

export const AfterWithdrawalFromTheImpoundLot: React.FC<IBeforeAndAfterTireFitting> = ({
  task,
  refetchTask,
  refetchNavigation,
}) => {
  const { control, handleSubmit, watch, setValue, clearErrors, trigger } = useForm<IPostWithdrawalFromTheImpoundLot>({
    defaultValues: {
      task: task.id,
      receipt_amount: 0.0,
      payment_method: '',
      evacuation_reason: '',
      additional_services: '',
      registration_on_self: '',
      without_sts: '',
      license_plate_installation: '',
      detention_initiator: '',
      initiator_location: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [afterWithdrawalFromTheImpoundLotCreate, afterWithdrawalFromTheImpoundLotCreateResult] =
    usePostAfterWithdrawalFromTheImpoundLotCreateMutation();
  React.useEffect(() => {
    if (task?.carsharing) {
      if (task.after_withdrawal_from_the_impound_lot !== null) {
        setValue('receipt_amount', task.after_withdrawal_from_the_impound_lot.receipt_amount);
        setValue('payment_method', task.after_withdrawal_from_the_impound_lot.payment_method);
        setValue('evacuation_reason', task.after_withdrawal_from_the_impound_lot.evacuation_reason);
        setValue('additional_services', task.after_withdrawal_from_the_impound_lot.additional_services);
        setValue('registration_on_self', task.after_withdrawal_from_the_impound_lot.registration_on_self);
        setValue('without_sts', task.after_withdrawal_from_the_impound_lot.without_sts);
        setValue('license_plate_installation', task.after_withdrawal_from_the_impound_lot.license_plate_installation);
        setValue('detention_initiator', task.after_withdrawal_from_the_impound_lot.detention_initiator);
        setValue('initiator_location', task.after_withdrawal_from_the_impound_lot.initiator_location);
      }
    }
  }, []);

  const onSubmit = async (data: IPostWithdrawalFromTheImpoundLot) => {
    try {
      if (!data) return;
      const sanitizedData = {
        ...data,
        registration_on_self: data.additional_services === 'no' ? '' : data.registration_on_self,
        without_sts: data.additional_services === 'no' ? '' : data.without_sts,
        license_plate_installation: data.additional_services === 'no' ? '' : data.license_plate_installation,
      };
      await toast.promise(afterWithdrawalFromTheImpoundLotCreate(sanitizedData).unwrap(), {
        success: 'Данные конфигурации успешно отправлены 👌',
        pending: 'Пожалуйста, подождите',
        error: 'Ошибка при отправке данных конфигурации 🤯',
      });
      refetchTask();
      refetchNavigation();
    } catch (error) {
      toast.error('Проверьте данные формы');
      console.error('Ошибка валидации:', error);
    }
  };

  return (
    <Box sx={{ flex: 1, mt: 1, mr: 1, ml: 1 }}>
      <Controller
        name="evacuation_reason"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            onChange={(event, newValue) => {
              if (newValue.id !== field.value) {
                setValue('evacuation_reason', newValue.id, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }
            }}
            value={
              evacuationReasonsTypes.find((option) => option.id === field.value) || {
                id: '',
                label: '',
              }
            }
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Причина эвакуации"
                error={!!error}
                helperText={error ? error.message : ''}
              />
            )}
            options={evacuationReasonsTypes}
          />
        )}
      />
      <Controller
        name="receipt_amount"
        control={control}
        rules={{
          required: 'Обязательно для заполнения',
          pattern: {
            value: /^\d+(\.\d{1,2})?$/,
            message: 'Введите корректную сумму (0.00)',
          },
          validate: (value) => {
            const numericValue = Number(value);
            return (!isNaN(numericValue) && numericValue > 0) || 'Сумма должна быть больше 0';
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            freeSolo // Позволяет вводить произвольные числа
            options={fixedAmounts} // Фиксированные суммы
            noOptionsText="Введите сумму вручную"
            value={field.value !== undefined ? field.value.toString() : ''} // Приводим число к строке
            onChange={(event, newValue) => {
              const parsedValue = Number(newValue);
              if (!isNaN(parsedValue)) {
                setValue('receipt_amount', parsedValue, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }
            }}
            onInputChange={(event, newInputValue) => {
              const parsedValue = Number(newInputValue.replace(/,/g, '.')); // Поддержка ввода с запятой
              if (!isNaN(parsedValue)) {
                setValue('receipt_amount', parsedValue, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Сумма чека"
                variant="outlined"
                size="small"
                fullWidth
                error={!!error}
                helperText={error ? error.message : ''}
                sx={{ marginBottom: 2 }}
              />
            )}
          />
        )}
      />
      <Controller
        name="additional_services"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            onChange={(event, newValue) => {
              if (newValue.id !== field.value) {
                setValue('additional_services', newValue.id, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }
              if (newValue.id === 'no') {
                // Если "Да", сбрасываем ошибки
                clearErrors('registration_on_self');
                clearErrors('without_sts');
                clearErrors('license_plate_installation');
              } else {
                // Если "Нет", форсируем валидацию
                trigger('registration_on_self');
                trigger('without_sts');
                trigger('license_plate_installation');
              }
            }}
            value={
              optionsAreTheWheelsAndTiresTheSameBrandAndType.find((option) => option.id === field.value) || {
                id: '',
                label: '',
              }
            }
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Дополнительные услуги"
                error={!!error}
                helperText={error ? error.message : ''}
              />
            )}
            options={optionsAreTheWheelsAndTiresTheSameBrandAndType}
          />
        )}
      />
      {watch('additional_services') === 'yes' && (
        <Box sx={{ mb: 5, mt: 5 }}>
          <Controller
            name="registration_on_self"
            control={control}
            rules={{ required: 'Обязательно для заполнения' }}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                size="small"
                fullWidth
                disableClearable
                sx={{ marginBottom: 2 }}
                onChange={(event, newValue) => {
                  if (newValue.id !== field.value) {
                    setValue('registration_on_self', newValue.id, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }
                }}
                value={
                  optionsAreTheWheelsAndTiresTheSameBrandAndType.find((option) => option.id === field.value) || {
                    id: '',
                    label: '',
                  }
                }
                noOptionsText="Не найдено"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Оформление на себя"
                    error={!!error}
                    helperText={error ? error.message : ''}
                  />
                )}
                options={optionsAreTheWheelsAndTiresTheSameBrandAndType}
              />
            )}
          />
          <Controller
            name="without_sts"
            control={control}
            rules={{ required: 'Обязательно для заполнения' }}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                size="small"
                fullWidth
                disableClearable
                sx={{ marginBottom: 2 }}
                onChange={(event, newValue) => {
                  if (newValue.id !== field.value) {
                    setValue('without_sts', newValue.id, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }
                }}
                value={
                  optionsAreTheWheelsAndTiresTheSameBrandAndType.find((option) => option.id === field.value) || {
                    id: '',
                    label: '',
                  }
                }
                noOptionsText="Не найдено"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField {...params} label="Без СТС" error={!!error} helperText={error ? error.message : ''} />
                )}
                options={optionsAreTheWheelsAndTiresTheSameBrandAndType}
              />
            )}
          />
          <Controller
            name="license_plate_installation"
            control={control}
            rules={{ required: 'Обязательно для заполнения' }}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                size="small"
                fullWidth
                disableClearable
                sx={{ marginBottom: 2 }}
                onChange={(event, newValue) => {
                  if (newValue.id !== field.value) {
                    setValue('license_plate_installation', newValue.id, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }
                }}
                value={
                  optionsAreTheWheelsAndTiresTheSameBrandAndType.find((option) => option.id === field.value) || {
                    id: '',
                    label: '',
                  }
                }
                noOptionsText="Не найдено"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Установка ГРЗ"
                    error={!!error}
                    helperText={error ? error.message : ''}
                  />
                )}
                options={optionsAreTheWheelsAndTiresTheSameBrandAndType}
              />
            )}
          />
        </Box>
      )}
      <Controller
        name="payment_method"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            onChange={(event, newValue) => {
              if (newValue.id !== field.value) {
                setValue('payment_method', newValue.id, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }
            }}
            value={
              paymentMethodsTwoTypes.find((option) => option.id === field.value) || {
                id: '',
                label: '',
              }
            }
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField {...params} label="Вид оплаты" error={!!error} helperText={error ? error.message : ''} />
            )}
            options={paymentMethodsTwoTypes}
          />
        )}
      />
      <Controller
        name="detention_initiator"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            onChange={(event, newValue) => {
              if (newValue.id !== field.value) {
                setValue('detention_initiator', newValue.id, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }
            }}
            value={
              iniciatorsTypes.find((option) => option.id === field.value) || {
                id: '',
                label: '',
              }
            }
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Инициатор задержания"
                error={!!error}
                helperText={error ? error.message : ''}
              />
            )}
            options={iniciatorsTypes}
          />
        )}
      />
      <Controller
        name="initiator_location"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            freeSolo
            disableClearable
            sx={{ marginBottom: 2 }}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                setValue('initiator_location', newValue, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }
            }}
            onInputChange={(event, newInputValue) => {
              setValue('initiator_location', newInputValue, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
            value={field.value || ''}
            noOptionsText="Введите локацию вручную"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Местоположение инициатора"
                error={!!error}
                helperText={error ? error.message : ''}
              />
            )}
            options={locationOptions}
          />
        )}
      />
      <Box sx={{ position: 'fixed', bottom: '75px', left: '27px' }}>
        <Fab
          component="span"
          onClick={() => {
            refetchNavigation();
            refetchTask();
          }}
          color="primary"
        >
          <LuRefreshCcw size={24} />
        </Fab>
      </Box>
      <Box sx={{ position: 'fixed', bottom: '75px', right: '27px' }}>
        <Fab
          component="span"
          color="primary"
          disabled={afterWithdrawalFromTheImpoundLotCreateResult.isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          <LuUploadCloud size={24} />
        </Fab>
      </Box>
    </Box>
  );
};
