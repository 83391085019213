import { IWheel } from '../../screens/types';

export const getWheelTitle = (disk_location: string) => {
  if (disk_location === 'front_left') {
    return 'Переднее левое';
  } else if (disk_location === 'front_right') {
    return 'Переднее правое';
  } else if (disk_location === 'rear_left') {
    return 'Заднее левое';
  } else if (disk_location === 'rear_right') {
    return 'Заднее правое';
  } else if (disk_location === 'front_axle') {
    return 'Передняя ось';
  } else if (disk_location === 'rear_axle') {
    return 'Задняя ось';
  } else if (disk_location === 'all') {
    return 'Все колеса';
  }
};

export const DAMAGE_TYPES = ['Прокол', 'Порез', 'Грыжа', 'Нипель', 'Износ протектора', 'Не удалось установить'];

export const DISK_TYPES = [
  { id: 'molten_disk', label: 'Литой' },
  { id: 'stamped_disk', label: 'Штамп' },
];

export const SEASON_CHOICES_MAP = {
  summer: 'Летняя',
  winter_studded: 'Зимняя (Шипованная)',
  winter_sticky: 'Зимняя (Липучка)',
};

export const optionsHowWheelsMounted = [
  { id: 'all_wheels_have_the_same_rubber', label: 'На всех колесах одна резина' },
  { id: 'front_and_rear_are_different', label: 'Разная для передней и задней оси' },
  { id: 'everything_is_different', label: 'Все разные' },
];

export const optionsHowWheelsMountedModified = [
  { id: 'front_and_rear_are_different', label: 'Разная для передней и задней оси' },
  { id: 'everything_is_different', label: 'Все разные' },
];

export const optionsNumberDamagedWheels = [
  { id: '1', label: '1' },
  { id: '2', label: '2' },
  { id: '3', label: '3' },
];
export const optionsNumberDamagedWheelsWithZero = [
  { id: '0', label: '0' },
  { id: '1', label: '1' },
  { id: '2', label: '2' },
  { id: '3', label: '3' },
];

export const optionsNumberDamagedDisWheels = [
  { id: 0, label: '0' },
  { id: 1, label: '1' },
  { id: 2, label: '2' },
  { id: 3, label: '3' },
];

export const optionsAreTheWheelsAndTiresTheSameBrandAndType = [
  { id: 'yes', label: 'Да' },
  { id: 'no', label: 'Нет' },
];

export const locationOptions = ['Москва'];
export const fixedAmounts = [1000.00, 2500.00, 5000.00, 7500.00, 10000.00];

export const parkingTypes = [
  { id: 'multi_level', label: 'Многоуровневый паркинг' },
  { id: 'underground', label: 'Подземная' },
  { id: 'paid_surface', label: 'Наземная платная' },
  { id: 'free_surface', label: 'Наземная бесплатная' },
  { id: 'gated_area', label: 'Закрытая территория' },
  { id: 'closed_courtyard', label: 'Закрытый двор' },
];

export const paymentMethodsOneTypes = [
  { id: 'business_card', label: 'Бизнес-карта' },
  { id: 'cash', label: 'Наличные' },
];

export const paymentMethodsTwoTypes = [
  { id: 'business_card', label: 'Бизнес-карта' },
  { id: 'payment_order', label: 'Платежное поручение' },
  { id: 'post_payment', label: 'Постоплата' },
];

export const evacuationReasonsTypes = [
  { id: 'parking', label: 'Парковка' },
  { id: 'refusal', label: 'Отказ от мед. освидетельствования' },
  { id: 'sao', label: 'САО' },
  { id: '12.3.1', label: '12.3.1' },
  { id: 'ovd', label: 'ОВД' },
];

export const iniciatorsTypes = [
  { id: 'gibdd', label: 'ГИБДД' },
  { id: 'madi', label: 'МАДИ' },
  { id: 'mintrans', label: 'МинТранс' },
  { id: 'gust', label: 'ГУСТ' },
  { id: 'uratk', label: 'УРАТК' },
  { id: 'ovd', label: 'ОВД' },
];

export type SeasonKey = keyof typeof SEASON_CHOICES_MAP;

export const createInitialWheels = (taskId: string, stage: 'before' | 'after'): IWheel[] => {
  const baseWheel = {
    brand: '',
    model: '',
    size: '',
    radius: '',
    season: '',
    disk: '',
    reason: '',
    task: taskId,
    stage,
  };

  const positions: ('front_left' | 'front_right' | 'rear_left' | 'rear_right' | 'front_axle' | 'rear_axle' | 'all')[] =
    ['front_left', 'front_right', 'rear_left', 'rear_right', 'front_axle', 'rear_axle', 'all'];

  return positions.map((position) => ({
    ...baseWheel,
    position,
  }));
};
export const createInitialAfterWheels = (taskId: string, stage: 'before' | 'after'): IWheel[] => {
  const baseWheel = {
    brand: '',
    model: '',
    size: '',
    radius: '',
    season: '',
    disk: '',
    reason: '',
    task: taskId,
    stage,
  };

  const positions: ('front_left' | 'front_right' | 'rear_left' | 'rear_right')[] = [
    'front_left',
    'front_right',
    'rear_left',
    'rear_right',
  ];

  return positions.map((position) => ({
    ...baseWheel,
    position,
  }));
};

export const namesMapping: Record<string, string> = {
  is_front_left_wheel_damaged: 'front_left',
  is_front_right_wheel_damaged: 'front_right',
  is_rear_left_wheel_damaged: 'rear_left',
  is_rear_right_wheel_damaged: 'rear_right',
};

export const getDefaultValueString = (id: string | number | null, options: { id: string; label: string }[]) =>
  options.find((option) => option.id === String(id)) ?? null;

export const getDefaultValueNumber = (id: string | number | null, options: { id: number; label: string }[]) =>
  options.find((option) => option.id === Number(id)) ?? null;

export type WheelMountType =
  | 'all_wheels_have_the_same_rubber'
  | 'front_and_rear_are_different'
  | 'everything_is_different';
