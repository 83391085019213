import { Autocomplete, Box, Fab, TextField } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LuRefreshCcw, LuUploadCloud } from 'react-icons/lu';
import { toast } from 'react-toastify';
import {
  useLazyGetTireRadiusesQuery,
  usePostDismantlingAfterTaskCreateMutation,
  usePostWheelCreateMutation,
} from '../../../redux/services/tasks';
import { IPostDismantlingAfterTaskCreate, IWheel } from '../../../screens/types';
import { optionsAreTheWheelsAndTiresTheSameBrandAndType } from '../consts';
import { IBeforeAndAfterTireFitting } from '../types';
import { AfterSameAxes } from './after/after-same-axes';
import { AfterUnequalAxes } from './after/after-unequal-axes';

export const AfterDismantling: React.FC<IBeforeAndAfterTireFitting> = ({ task, refetchTask, refetchNavigation }) => {
  const { control, handleSubmit, watch, setValue } = useForm<IPostDismantlingAfterTaskCreate>({
    defaultValues: {
      task: task.id,
      are_both_axles_the_same: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const wheelFormRef = React.useRef<{ submitForm: () => IWheel[] } | null>(null);

  const [wheelCreate, wheelCreateResult] = usePostWheelCreateMutation();
  // prettier-ignore
  const [postDismantlingAfterTaskCreate, postDismantlingAfterTaskCreateResult] = usePostDismantlingAfterTaskCreateMutation();
  const [tireRadiuses, tireRadiusesResult] = useLazyGetTireRadiusesQuery();

  React.useEffect(() => {
    if (task?.carsharing) {
      tireRadiuses({ company: task.carsharing });
      if (task.after_dismantling !== null) {
        setValue('are_both_axles_the_same', task.after_dismantling.are_both_axles_the_same);
      }
    }
  }, []);

  const onSubmit = async (afterTireData: IPostDismantlingAfterTaskCreate) => {
    try {
      const wheelsData = await wheelFormRef.current?.submitForm();
      if (!wheelsData) {
        return;
      }
      await toast.promise(postDismantlingAfterTaskCreate(afterTireData).unwrap(), {
        success: 'Данные конфигурации успешно отправлены 👌',
        pending: 'Пожалуйста, подождите',
        error: 'Ошибка при отправке данных конфигурации 🤯',
      });
      await toast.promise(wheelCreate(wheelsData).unwrap(), {
        success: 'Данные по колесам успешно отправлены 👌',
        pending: 'Пожалуйста, подождите',
        error: 'Ошибка при отправке данных по колесам 🤯',
      });
      refetchTask();
      refetchNavigation();
    } catch (error) {
      toast.error('Проверьте данные формы');
      console.error('Ошибка валидации колес:', error);
    }
  };

  return (
    <Box sx={{ flex: 1, mt: 1, mr: 1, ml: 1 }}>
      <Controller
        name="are_both_axles_the_same"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            onChange={(event, newValue) => {
              if (newValue.id !== field.value) {
                setValue('are_both_axles_the_same', newValue.id, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }
            }}
            value={
              optionsAreTheWheelsAndTiresTheSameBrandAndType.find((option) => option.id === field.value) || {
                id: '',
                label: '',
              }
            }
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Укажите, одинаковые ли обе оси"
                error={!!error}
                helperText={error ? error.message : ''}
              />
            )}
            options={optionsAreTheWheelsAndTiresTheSameBrandAndType}
          />
        )}
      />
      {watch('are_both_axles_the_same') === 'yes' && (
        <Box sx={{ paddingBottom: '64px' }}>
          <AfterSameAxes ref={wheelFormRef} task={task} stage="after" tireRadiuses={tireRadiusesResult.data} />
        </Box>
      )}
      {watch('are_both_axles_the_same') === 'no' && (
        <Box sx={{ paddingBottom: '64px' }}>
          <AfterUnequalAxes ref={wheelFormRef} task={task} stage="after" tireRadiuses={tireRadiusesResult.data} />
        </Box>
      )}
      <Box sx={{ position: 'fixed', bottom: '75px', left: '27px', zIndex: 10 }}>
        <Fab
          component="span"
          onClick={() => {
            refetchNavigation();
            refetchTask();
          }}
          color="primary"
        >
          <LuRefreshCcw size={24} />
        </Fab>
      </Box>
      <Box sx={{ position: 'fixed', bottom: '75px', right: '27px' }}>
        <Fab
          component="span"
          color="primary"
          disabled={wheelCreateResult.isLoading || postDismantlingAfterTaskCreateResult.isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          <LuUploadCloud size={24} />
        </Fab>
      </Box>
    </Box>
  );
};
