import { Autocomplete, Box, Fab, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LuRefreshCcw, LuUploadCloud } from 'react-icons/lu';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  useLazyGetTireRadiusesQuery,
  usePostTireFittingBeforeTaskCreateMutation,
  usePostWheelCreateMutation,
} from '../../../redux/services/tasks';
import { IPostTireFittingBeforeTaskCreate, IWheel, TBeforeTire } from '../../../screens/types';
import { optionsHowWheelsMounted, optionsNumberDamagedWheelsWithZero } from '../consts';
import { IBeforeAndAfterTireFitting } from '../types';
import { BeforeAllWheelsHaveTheSameRubber } from '../tire-fitting/before/before-all-wheels-have-the-same-rubber';
import { BeforeFrontAndReadAreDifferent } from '../tire-fitting/before/before-front-and-read-are-different';
import { BeforeEverythingIsDifferent } from '../tire-fitting/before/before-everything-is-different';

export const BeforeDismantling: React.FC<IBeforeAndAfterTireFitting> = ({ task, refetchTask, refetchNavigation }) => {
  const { control, handleSubmit, watch, setValue, getValues } = useForm<IPostTireFittingBeforeTaskCreate>({
    defaultValues: {
      task: task.id,
      how_are_the_wheels_mounted_on_the_car: '',
      how_many_wheels_are_damaged: '',
      is_front_left_wheel_damaged: '',
      is_front_right_wheel_damaged: '',
      is_rear_left_wheel_damaged: '',
      is_rear_right_wheel_damaged: '',
      damaged_wheels: [],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const wheelFormRef = React.useRef<{ submitForm: () => IWheel[] } | null>(null);

  const [wheelCreate, wheelCreateResult] = usePostWheelCreateMutation();
  // prettier-ignore
  const [postTireFittingBeforeTaskCreate, postTireFittingBeforeTaskCreateResult] = usePostTireFittingBeforeTaskCreateMutation();
  const [tireRadiuses, tireRadiusesResult] = useLazyGetTireRadiusesQuery();

  React.useEffect(() => {
    if (task?.carsharing) {
      tireRadiuses({ company: task.carsharing });
      if (task.before_tire.length !== 0) {
        setValue('how_are_the_wheels_mounted_on_the_car', task.before_tire[0].how_are_the_wheels_mounted_on_the_car);
        setValue('how_many_wheels_are_damaged', task.before_tire[0].how_many_wheels_are_damaged.toString());
        const wheelKeys: (keyof TBeforeTire)[] = [
          'is_front_left_wheel_damaged',
          'is_front_right_wheel_damaged',
          'is_rear_left_wheel_damaged',
          'is_rear_right_wheel_damaged',
        ];
        const damagedWheels = wheelKeys.filter((key) => task.before_tire[0][key] === 'yes');
        setValue('damaged_wheels', damagedWheels, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    }
  }, []);

  const allowedCount =
    watch('how_many_wheels_are_damaged') && !isNaN(parseInt(watch('how_many_wheels_are_damaged'), 10))
      ? parseInt(watch('how_many_wheels_are_damaged'), 10)
      : 0;

  const onSubmit = async (parentData: IPostTireFittingBeforeTaskCreate) => {
    try {
      const wheelsData = await wheelFormRef.current?.submitForm();
      if (!wheelsData) {
        return;
      }
      const beforeTireData = parentData;
      const formData = getValues();
      const wheelsDamage = {
        is_front_left_wheel_damaged: formData.damaged_wheels.includes('is_front_left_wheel_damaged') ? 'yes' : 'no',
        is_front_right_wheel_damaged: formData.damaged_wheels.includes('is_front_right_wheel_damaged') ? 'yes' : 'no',
        is_rear_left_wheel_damaged: formData.damaged_wheels.includes('is_rear_left_wheel_damaged') ? 'yes' : 'no',
        is_rear_right_wheel_damaged: formData.damaged_wheels.includes('is_rear_right_wheel_damaged') ? 'yes' : 'no',
      };
      await toast.promise(
        postTireFittingBeforeTaskCreate({
          ...beforeTireData,
          ...wheelsDamage,
        }).unwrap(),
        {
          success: 'Данные конфигурации успешно отправлены 👌',
          pending: 'Пожалуйста, подождите',
          error: 'Ошибка при отправке данных конфигурации 🤯',
        },
      );
      await toast.promise(wheelCreate(wheelsData).unwrap(), {
        success: 'Данные по колесам успешно отправлены 👌',
        pending: 'Пожалуйста, подождите',
        error: 'Ошибка при отправке данных по колесам 🤯',
      });
      refetchTask();
      refetchNavigation();
    } catch (error) {
      toast.error('Проверьте данные формы');
      console.error('Ошибка валидации колес:', error);
    }
  };

  return (
    <Box sx={{ flex: 1, mt: 1, mr: 1, ml: 1 }}>
      <Controller
        name="how_are_the_wheels_mounted_on_the_car"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            onChange={(event, newValue) => {
              if (newValue.id !== field.value) {
                setValue('how_are_the_wheels_mounted_on_the_car', newValue.id, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }
            }}
            value={optionsHowWheelsMounted.find((option) => option.id === field.value) || { id: '', label: '' }}
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Как на машине установлена резина"
                error={!!error}
                helperText={error ? error.message : ''}
              />
            )}
            options={optionsHowWheelsMounted}
          />
        )}
      />
      {watch('how_are_the_wheels_mounted_on_the_car') !== '' && (
        <>
          <Controller
            name="how_many_wheels_are_damaged"
            control={control}
            rules={{ required: 'Обязательно для заполнения' }}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                size="small"
                fullWidth
                disableClearable
                sx={{ marginBottom: 2 }}
                onChange={(event, newValue) => {
                  if (newValue.id !== field.value) {
                    setValue('how_many_wheels_are_damaged', newValue.id, { shouldValidate: true, shouldDirty: true });
                  }
                }}
                value={
                  optionsNumberDamagedWheelsWithZero.find((option) => option.label === field.value) || {
                    id: '',
                    label: '',
                  }
                }
                noOptionsText="Не найдено"
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Количество поврежденных колес"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
                options={optionsNumberDamagedWheelsWithZero}
              />
            )}
          />
          {watch('how_many_wheels_are_damaged') !== '0' && watch('how_many_wheels_are_damaged') !== '' && (
            <Controller
              name="damaged_wheels"
              control={control}
              defaultValue={[] as string[]}
              rules={{
                validate: (value: string[]) => value.length === allowedCount || 'Несовпадение количества колес',
              }}
              render={({ field, fieldState: { error } }) => {
                const selectedWheels = field.value as string[];
                return (
                  <>
                    <Typography sx={{ textAlign: 'center', marginBottom: 1, color: error ? '#d32f2f' : '#000' }}>
                      Укажите поврежденные колеса
                    </Typography>
                    <ToggleButtonGroup
                      value={selectedWheels}
                      onChange={(event, newValue: string[]) => {
                        // Если задано ограничение и выбор превышает его – не обновляем
                        if (allowedCount && newValue.length > allowedCount) return;
                        field.onChange(newValue);
                      }}
                      // Блокируем, если количество колес не указано или равно 0
                      disabled={allowedCount === 0}
                      sx={{
                        width: '100%',
                        marginBottom: 2,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <ToggleButton
                        value="is_front_left_wheel_damaged"
                        sx={{ width: '24%' }}
                        disabled={
                          allowedCount > 0 &&
                          selectedWheels.length >= allowedCount &&
                          !selectedWheels.includes('is_front_left_wheel_damaged')
                        }
                      >
                        <Typography sx={{ fontSize: 12 }}>
                          Переднее
                          <br />
                          левое
                        </Typography>
                      </ToggleButton>
                      <ToggleButton
                        value="is_front_right_wheel_damaged"
                        sx={{ width: '25%' }}
                        disabled={
                          allowedCount > 0 &&
                          selectedWheels.length >= allowedCount &&
                          !selectedWheels.includes('is_front_right_wheel_damaged')
                        }
                      >
                        <Typography sx={{ fontSize: 12 }}>
                          Переднее
                          <br />
                          правое
                        </Typography>
                      </ToggleButton>
                      <ToggleButton
                        value="is_rear_left_wheel_damaged"
                        sx={{ width: '25%' }}
                        disabled={
                          allowedCount > 0 &&
                          selectedWheels.length >= allowedCount &&
                          !selectedWheels.includes('is_rear_left_wheel_damaged')
                        }
                      >
                        <Typography sx={{ fontSize: 12 }}>
                          Заднее
                          <br />
                          левое
                        </Typography>
                      </ToggleButton>
                      <ToggleButton
                        value="is_rear_right_wheel_damaged"
                        sx={{ width: '24%' }}
                        disabled={
                          allowedCount > 0 &&
                          selectedWheels.length >= allowedCount &&
                          !selectedWheels.includes('is_rear_right_wheel_damaged')
                        }
                      >
                        <Typography sx={{ fontSize: 12 }}>
                          Заднее
                          <br />
                          правое
                        </Typography>
                      </ToggleButton>
                    </ToggleButtonGroup>
                    {error && (
                      <Typography variant="caption" color="error">
                        {error.message}
                      </Typography>
                    )}
                  </>
                );
              }}
            />
          )}
        </>
      )}
      {watch('how_are_the_wheels_mounted_on_the_car') === 'all_wheels_have_the_same_rubber' && (
        <Box sx={{ paddingBottom: '64px' }}>
          <BeforeAllWheelsHaveTheSameRubber
            ref={wheelFormRef}
            task={task}
            stage="before"
            tireRadiuses={tireRadiusesResult.data}
          />
        </Box>
      )}
      {watch('how_are_the_wheels_mounted_on_the_car') === 'front_and_rear_are_different' && (
        <Box sx={{ paddingBottom: '64px' }}>
          <BeforeFrontAndReadAreDifferent
            ref={wheelFormRef}
            task={task}
            stage="before"
            tireRadiuses={tireRadiusesResult.data}
          />
        </Box>
      )}
      {watch('how_are_the_wheels_mounted_on_the_car') === 'everything_is_different' && (
        <Box sx={{ paddingBottom: '64px' }}>
          <BeforeEverythingIsDifferent
            ref={wheelFormRef}
            task={task}
            stage="before"
            tireRadiuses={tireRadiusesResult.data}
          />
        </Box>
      )}
      <Box sx={{ position: 'fixed', bottom: '75px', left: '27px', zIndex: 10 }}>
        <Fab
          component="span"
          onClick={() => {
            refetchNavigation();
            refetchTask();
          }}
          color="primary"
        >
          <LuRefreshCcw size={24} />
        </Fab>
      </Box>
      <Box sx={{ position: 'fixed', bottom: '75px', right: '27px' }}>
        <Fab
          component="span"
          color="primary"
          disabled={wheelCreateResult.isLoading || postTireFittingBeforeTaskCreateResult.isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          <LuUploadCloud size={24} />
        </Fab>
      </Box>
    </Box>
  );
};
