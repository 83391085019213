import { Autocomplete, Box, Fab, TextField } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LuRefreshCcw, LuUploadCloud } from 'react-icons/lu';
import { toast } from 'react-toastify';
import { usePostAfterWithdrawalFromPaidParkingCreateMutation } from '../../redux/services/tasks';
import { IBeforeAndAfterTireFitting, IPostWithdrawalFromPaidParking } from '../../screens/types';
import { optionsAreTheWheelsAndTiresTheSameBrandAndType, parkingTypes, paymentMethodsOneTypes } from './consts';

export const AfterWithdrawalFromPaidParking: React.FC<IBeforeAndAfterTireFitting> = ({
  task,
  refetchTask,
  refetchNavigation,
}) => {
  const { control, handleSubmit, watch, setValue, clearErrors, trigger } = useForm<IPostWithdrawalFromPaidParking>({
    defaultValues: {
      task: task.id,
      receipt_amount: 0.0,
      payment_method: '',
      parking_type: '',
      car_released: '',
      reason_for_failure: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [afterWithdrawalFromPaidParkingCreate, afterWithdrawalFromPaidParkingCreateResult] =
    usePostAfterWithdrawalFromPaidParkingCreateMutation();
  React.useEffect(() => {
    if (task?.carsharing) {
      if (task.after_withdrawal_from_paid_parking !== null) {
        setValue('parking_type', task.after_withdrawal_from_paid_parking.parking_type);
        setValue('car_released', task.after_withdrawal_from_paid_parking.car_released);
        setValue('reason_for_failure', task.after_withdrawal_from_paid_parking.reason_for_failure);
        setValue('receipt_amount', task.after_withdrawal_from_paid_parking.receipt_amount);
        setValue('payment_method', task.after_withdrawal_from_paid_parking.payment_method);
      }
    }
  }, []);

  const onSubmit = async (data: IPostWithdrawalFromPaidParking) => {
    try {
      if (!data) return;
      const sanitizedData = {
        ...data,
        reason_for_failure: data.car_released === 'yes' ? '' : data.reason_for_failure,
      };
      await toast.promise(afterWithdrawalFromPaidParkingCreate(sanitizedData).unwrap(), {
        success: 'Данные конфигурации успешно отправлены 👌',
        pending: 'Пожалуйста, подождите',
        error: 'Ошибка при отправке данных конфигурации 🤯',
      });
      refetchTask();
      refetchNavigation();
    } catch (error) {
      toast.error('Проверьте данные формы');
      console.error('Ошибка валидации:', error);
    }
  };

  return (
    <Box sx={{ flex: 1, mt: 1, mr: 1, ml: 1 }}>
      <Controller
        name="parking_type"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            onChange={(event, newValue) => {
              if (newValue.id !== field.value) {
                setValue('parking_type', newValue.id, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }
            }}
            value={
              parkingTypes.find((option) => option.id === field.value) || {
                id: '',
                label: '',
              }
            }
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Выберите вид парковки"
                error={!!error}
                helperText={error ? error.message : ''}
              />
            )}
            options={parkingTypes}
          />
        )}
      />
      <Controller
        name="receipt_amount"
        control={control}
        rules={{
          required: 'Обязательно для заполнения',
          pattern: {
            value: /^\d+(\.\d{1,2})?$/,
            message: 'Введите корректную сумму (0.00)',
          },
          validate: (value) => {
            const numericValue = Number(value); // Приведение к числу
            return (!isNaN(numericValue) && numericValue > 0) || 'Сумма должна быть больше 0';
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Сумма чека"
            variant="outlined"
            size="small"
            fullWidth
            error={!!error}
            helperText={error ? error.message : ''}
            sx={{ marginBottom: 2 }}
            onChange={(e) => {
              let value = e.target.value.replace(/,/g, '.'); // Заменяем запятую на точку
              if (/^\d+(\.\d{0,2})?$/.test(value) || value === '') {
                field.onChange(value);
              }
            }}
          />
        )}
      />
      <Controller
        name="payment_method"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            onChange={(event, newValue) => {
              if (newValue.id !== field.value) {
                setValue('payment_method', newValue.id, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }
            }}
            value={
              paymentMethodsOneTypes.find((option) => option.id === field.value) || {
                id: '',
                label: '',
              }
            }
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField {...params} label="Вид оплаты" error={!!error} helperText={error ? error.message : ''} />
            )}
            options={paymentMethodsOneTypes}
          />
        )}
      />
      <Controller
        name="car_released"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            onChange={(event, newValue) => {
              if (newValue.id !== field.value) {
                setValue('car_released', newValue.id, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }
              if (newValue?.id === 'yes') {
                // Если "Да", очищаем причину и сбрасываем ошибки
                clearErrors('reason_for_failure');
              } else {
                // Если "Нет", форсируем валидацию
                trigger('reason_for_failure');
              }
            }}
            value={
              optionsAreTheWheelsAndTiresTheSameBrandAndType.find((option) => option.id === field.value) || {
                id: '',
                label: '',
              }
            }
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Укажите, удалось ли вывести автомобиль"
                error={!!error}
                helperText={error ? error.message : ''}
              />
            )}
            options={optionsAreTheWheelsAndTiresTheSameBrandAndType}
          />
        )}
      />
      {watch('car_released') === 'no' && (
        <Controller
          name="reason_for_failure"
          control={control}
          rules={{
            required: 'Причина обязательна',
            minLength: { value: 50, message: 'Причина должна быть не менее 50 символов.' },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Причина, по которой не удалось вывести ТС"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              error={!!error}
              helperText={error ? error.message : ''}
            />
          )}
        />
      )}
      <Box sx={{ position: 'fixed', bottom: '75px', left: '27px' }}>
        <Fab
          component="span"
          onClick={() => {
            refetchNavigation();
            refetchTask();
          }}
          color="primary"
        >
          <LuRefreshCcw size={24} />
        </Fab>
      </Box>
      <Box sx={{ position: 'fixed', bottom: '75px', right: '27px' }}>
        <Fab
          component="span"
          color="primary"
          disabled={afterWithdrawalFromPaidParkingCreateResult.isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          <LuUploadCloud size={24} />
        </Fab>
      </Box>
    </Box>
  );
};
