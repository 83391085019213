// unauthorized-component
export enum UnauthorizedScreenTypes {
  WELCOME_SCREEN = 'welcome',
  SIGNIN_SCREEN = 'signin',
  SIGNUP_SCREEN = 'signup',
  RESET_PASSWORD_SCREEN = 'reset_password',
  RESET_PASSWORD_CONFIRM_SCREEN = 'reset_password_confirm',
  PRIVACY_POLICY_SCREEN = 'privacy_policy',
  VERIFY_EMAIL_SCREEN = 'verify_email',
  RESEND_EMAIl_SCREEN = 'resend_email',
  PRIVACY_POLICY_EN_SCREEN = 'privacy_policy_en',
  ACCOUNT_DELETE_SCREEN = 'account_delete',
  FAQ_SCREEN = 'faq_screen',
  FEEDBACK_SCREEN = 'feedback_screen',
}

export interface ErrorWithData {
  status: number;
  data: {
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
    city?: string;
    admin_type?: string;
    password1?: string;
    password2?: string;
    new_password1?: string;
    new_password2?: string;
    token: string;

    detail?: string;
    non_field_errors?: string;
    message?: string;
  };
}

export type ErrorWithDetail = {
  status: number;
  data: {
    detail: string;
    non_field_errors: string;
    new_password1: string;
    new_password2: string;
  };
};

// Входные типы
export interface ISigninInputs {
  email: string;
  password: string;
}

export interface IMiddleNameInputs {
  middle_name: string;
}

export interface ISignupInputs {
  first_name: string;
  last_name: string;
  middle_name: string;
  email: string;
  phone: string;
  city: string;
  admin_type: string;
  password1: string;
  password2: string;
}

export interface IEmailData {
  email: string;
}

export interface IEmailConfirmData {
  key: string;
}

export interface IResetPasswordData {
  email: string;
}

export interface IResetPasswordConfirm {
  uid: string;
  token: string;
  new_password1: string;
  new_password2: string;
}

export interface IChangePasswordData {
  old_password: string;
  new_password1: string;
  new_password2: string;
}

export interface ITokenVerify {
  token: string;
}

export interface IResponseRefresh {
  access_expiration: string;
  access: string;
}

export interface IImageUpload {
  apiLink: string;
  formData: FormData;
}

export interface IVideoPresignedUpload {
  apiLink: string;
  formData: FormData;
}

export interface IVideoPresignedUploadResponse {
  url: string;
  filename: string;
}

export interface IFeedbackQuestionCreate {
  category: string;
  email: string;
  name: string;
  message: string;
}

export interface ITaskCreate {
  carsharing: string;
  type: string;
  car_model?: string;
  car_plate?: string;
  transit_season_tire_location?: string;
  distance?: string;
  mkad_bool?: string;
  kilometers_km?: string;
  is_toll_road?: string;
  reason_for_empty_exit?: string;
  type_of_vehicle_fleet?: string;
  help_bool?: string;
  help_user?: string;
  radius?: string;
  receiving_area?: string;
  tire_location?: string;
  wash?: string;
  choose_service?: string;
  category_service?: string;
  description?: string;
  vin_number?: string;
  waybill?: string;
  destination?: string;
  departure?: string;
  destination_address?: string;
  category?: string;
  direction?: string;
  mileage_before_task?: string;
  car_type?: string;
  location_keys?: string;
  subcategory?: string;
  impound_lot_address?: string;
}

// Ответные типы
export interface IResponseLoginData {
  access: string | null;
  refresh: string | null;
  isAuthenticated: boolean;
}

export interface IResponseDetail {
  detail: string;
}

type TCity = {
  id: string;
  name: string;
};

export interface IResponseCities {
  results: TCity[];
}

export interface IResponseProfile {
  first_name: string;
  last_name: string;
  middle_name: string;
  email: string;
  phone: string;
  city: string;
  type: [];
}

export interface IResponseCount {
  count: string;
}

export interface IResponseFinance {
  finances_day: string;
  finances_month_first: string;
  finances_month_second: string;
}

export type TTask = {
  id: string;
  car_model: string;
  car_plate: string;
  carsharing: 'Делимобиль' | 'СитиДрайв' | 'ГАЗ' | 'ЯрКамп' | 'Белка' | 'Яндекс' | 'Аит';
  created_at: string;
  created_by: string;
  finished_at: string;
  type: string;
  type_display: string;
  vin_number: string;
};

export interface IOpenTask extends TTask {
  photobeforetransit_set: TPhoto[];
  photoaftertransit_set: TPhoto[];
  photobeforewash_set: TPhoto[];
  photoafterwash_set: TPhoto[];
  photobeforecleaning_set: TPhoto[];
  photoaftercleaning_set: TPhoto[];
  photobeforemileage_set: TPhoto[];
  photoaftermileage_set: TPhoto[];
  photobeforetire_set: TPhoto[];
  photoaftertire_set: TPhoto[];
  photototal_set: TPhoto[];
  photodamage_set: TPhoto[];
  photonavigator_set: TPhoto[];
  before_tire: TBeforeTire[];
  after_tire: TAfterTire[];
  after_dismantling: TAfterDismantling;
  after_withdrawal_from_paid_parking: IWithdrawalFromPaidParking;
  after_withdrawal_from_the_impound_lot: IWithdrawalFromTheImpoundLot;
  antifreeze_liquid_liters_new: string;
  transit_season_tire_before: string;
  transit_season_tire_after: string;
  mileage_before_task: number;
  mileage_after_task: number;
  car_condition: string;
  car_condition_after: string;
  car_condition_comment: string;
  car_condition_comment_after: string;
  direction: string;
  location_keys: string;
  was_there_dry_cleaning: string;
  was_antifreeze_added: string;
  wheels: IWheel[];
}

export type TPhoto = {
  id: number;
  image: string;
  filename: string;
  name?: string;
};

export type TBeforeTire = {
  id: number;
  how_are_the_wheels_mounted_on_the_car: string;
  how_many_wheels_are_damaged: string;
  is_front_left_wheel_damaged: string;
  is_front_right_wheel_damaged: string;
  is_rear_left_wheel_damaged: string;
  is_rear_right_wheel_damaged: string;
};

export type TAfterTire = {
  id: number;
  are_the_wheels_and_tires_the_same_brand_and_type: string;
  how_are_the_wheels_installed_on_the_car_after_replacement: string;
  how_many_wheels_are_replaced: string;
};

export type TAfterDismantling = {
  id: number;
  are_both_axles_the_same: string;
};

export type TVideo = {
  id: number;
  video: string;
  filename: string;
};

export interface IResponseTasks {
  count: number;
  next: string;
  previous: string;
  results: TTask[];
}

type TCarsharing = {
  id: string;
  name: string;
};

export interface IResponseCarsharingList {
  results: TCarsharing[];
}

export interface IResponseTaskTypesList {
  name: string;
  value: string;
}

type TResponseCarsByCarsharingList = {
  name: string;
};

export interface IDataPostDelete {
  ids: string[];
  apiLink: string;
}

export interface IResponseCarsByCarsharingList {
  results: TResponseCarsByCarsharingList[];
}

export type TResponseTireBrandByCarsharingList = {
  brand: string;
};

export type TResponseTireModelByCarsharingList = {
  model: string;
};

export type TResponseTireSizeByCarsharingList = {
  size: string;
};

export type TResponseTireRadiuses = {
  radius: string;
};

export type TResponseTireSeasonByCarsharingList = {
  season: string;
};

type TResponseField = {
  name: string;
  value: string;
};

type TLocationField = {
  address: string;
};

type TWash = {
  id: string;
  address: string;
};

type THelpList = {
  id: string;
  first_name: string;
  last_name: string;
};

export interface IResponseFieldListToDrawer {
  id?: boolean;
  car_model?: boolean;
  car_plate?: boolean;
  carsharing?: boolean;
  created_at?: boolean;
  can_delete?: boolean;
  type_display?: boolean;
  distance_display?: boolean;
  help_user?: boolean;
  radius_display?: boolean;

  category?: boolean;
  category_service?: boolean;
  choose_service?: boolean;
  departure?: boolean;
  description?: boolean;
  destination?: boolean;
  destination_address?: boolean;
  finished_at?: boolean;
  fuel_bool?: boolean;
  full_name?: boolean;
  kilometers_km?: boolean;
  mileage_after_task?: boolean;
  mileage_before_task?: boolean;
  mileage_task?: boolean;
  mkad_bool?: boolean;
  radius?: boolean;
  status?: boolean;
  tire_fitting_option?: boolean;
  type?: boolean;
  vin_number?: boolean;
  wash?: boolean;
  wash_option?: boolean;
  waybill?: boolean;
  has_antifreeze_liquid?: boolean;
  antifreeze_liquid_liters_first?: boolean;
  antifreeze_liquid_liters_last?: boolean;
  antifreeze_liquid_liters_new?: boolean;
}

export interface IResponseFieldListByCarsharingAndTypes {
  car_plate?: boolean;
  car_model?: boolean;
  car_plate_autocomplete?: string[];
  distance?: TResponseField[];
  mkad_bool?: boolean;
  mkad_km?: boolean;
  is_toll_road?: boolean;
  reason_for_empty_exit?: TResponseField[];
  type_fleet?: TResponseField[];
  help_list?: THelpList[];
  wash_address?: TWash[];
  impound_lot_address?: TWash[];
  choose_service?: boolean;
  category_service?: boolean;
  choose_service_list?: boolean;
  category_service_list?: boolean;
  km?: boolean;
  mileage_before_task?: boolean;
  car_type?: boolean;
  vin_number?: boolean;
  radius?: TResponseField[];
  description?: boolean;
  locations?: TResponseField[];
  category?: TResponseField[];
  receiving_areas?: TResponseField[];
  tire_locations?: TLocationField[];
  direction?: TResponseField[];
}

type TStage = {
  name: string;
  min_photo: number;
  max_photo: number;
  api_link: string;
  serializer_name:
    | 'photobeforetransit_set'
    | 'photoaftertransit_set'
    | 'photobeforewash_set'
    | 'photoafterwash_set'
    | 'photobeforecleaning_set'
    | 'photoaftercleaning_set'
    | 'photobeforemileage_set'
    | 'photoaftermileage_set'
    | 'photobeforetire_set'
    | 'photoaftertire_set'
    | 'photototal_set'
    | 'photodamage_set'
    | 'photonavigator_set'
    | 'mileage_before_task'
    | 'mileage_after_task'
    | 'antifreeze_liquid_liters_new'
    | 'transit_season_tire_before'
    | 'transit_season_tire_after'
    | 'car_condition';
  image_name: string;
  require: string;
  photo_stage: string;
  input_stage: string;
  autocomplete_stage: string;
  autocomplete_options: [];
  select_stage: string;
  select_options: [];
  quiz_stage: string;
  video_stage: string;
  tire_fitting_before: string;
  tire_fitting_after: string;
  dismatling_before: string;
  dismatling_after: string;
  new_season_tire_fitting_before: string;
  new_season_tire_fitting_after: string;
  new_transit_season_tire_fitting_before: string;
  new_transit_season_tire_fitting_after: string;
  technique_before_information: string;
  technique_after_information: string;
  wash_after_information: string;
  after_cleaning: string;
  after_withdrawal_from_the_impound_lot: string;
  after_withdrawal_from_paid_parking: string;
};

export interface IResponseNavigation {
  name: string;
  stages: TStage[];
}

export interface IPostTireFittingBeforeTaskCreate {
  task: string;
  how_are_the_wheels_mounted_on_the_car: string;
  how_many_wheels_are_damaged: string;
  is_front_left_wheel_damaged: string;
  is_front_right_wheel_damaged: string;
  is_rear_left_wheel_damaged: string;
  is_rear_right_wheel_damaged: string;
  damaged_wheels: string[];
}

export interface IPostTireFittingAfterTaskCreate {
  task: string;
  are_the_wheels_and_tires_the_same_brand_and_type: string;
  how_are_the_wheels_installed_on_the_car_after_replacement: string;
  how_many_wheels_are_replaced: string;
}
export interface IPostDismantlingAfterTaskCreate {
  task: string;
  are_both_axles_the_same: string;
}

export interface IPostWithdrawalFromPaidParking {
  task: string;
  receipt_amount: number;
  payment_method: string;
  parking_type: string;
  car_released: string;
  reason_for_failure: string;
}

export interface IWithdrawalFromPaidParking {
  id: string;
  receipt_amount: number;
  payment_method: string;
  parking_type: string;
  car_released: string;
  reason_for_failure: string;
}

export interface IPostWithdrawalFromTheImpoundLot {
  task: string;
  receipt_amount: number;
  payment_method: string;
  evacuation_reason: string;
  additional_services: string;
  registration_on_self: string;
  without_sts: string;
  license_plate_installation: string;
  detention_initiator: string;
  initiator_location: string;
}

export interface IWithdrawalFromTheImpoundLot {
  id: string;
  receipt_amount: number;
  payment_method: string;
  evacuation_reason: string;
  additional_services: string;
  registration_on_self: string;
  without_sts: string;
  license_plate_installation: string;
  detention_initiator: string;
  initiator_location: string;
}

export interface IPostTireFittingBeforeTaskResponse {
  id: string;
}

export interface IPostDismantlingAfterTaskResponse {
  id: string;
  are_both_axles_the_same: string;
}

export interface IWheel {
  task: string;
  brand: string;
  model: string;
  size: string;
  radius: string;
  season: string;
  disk: string;
  stage: 'before' | 'after';
  position: 'front_left' | 'front_right' | 'rear_left' | 'rear_right' | 'front_axle' | 'rear_axle' | 'all';
  reason?: string;
}

export interface IBeforeAndAfterTireFitting {
  task: IOpenTask;
  refetchTask: () => object;
  refetchNavigation: () => object;
}

export interface IStageTireFitting {
  task: IOpenTask;
  stage: 'before' | 'after';
  tireRadiuses: TResponseTireRadiuses[] | undefined;
}
