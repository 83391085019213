import React from 'react';
import { Autocomplete, Box, Fab, TextField } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { IOpenTask, IResponseNavigation } from '../../screens/types';
import { LuRefreshCcw, LuUploadCloud } from 'react-icons/lu';
import { toast } from 'react-toastify';
import { useMultipleUpdateTaskMutation } from '../../redux/services/tasks';

interface IWashAfterInformation {
  id?: string;
  task: IOpenTask;
  activeStep: number;
  navigation: IResponseNavigation;
  refetchTask: () => {};
  refetchNavigation: () => {};
}

export const WashAfterInformation: React.FC<IWashAfterInformation> = ({
  id,
  task,
  navigation,
  activeStep,
  refetchTask,
  refetchNavigation,
}) => {
  const [multipleTaskUpdate, taskMultipleUpdateResult] = useMultipleUpdateTaskMutation();
  const [wasThereDryCleaning, setWasThereDryCleaning] = React.useState(task.was_there_dry_cleaning);
  const [wasAntifreezeAdded, setWasAntifreezeAdded] = React.useState(task.was_antifreeze_added);
  const [liquidLiters, setLiquidLiters] = React.useState(task.antifreeze_liquid_liters_new);

  const uploadDisabled = () => {
    if (taskMultipleUpdateResult.isLoading) {
      return true;
    }
    if (wasThereDryCleaning === '') {
      return true;
    }
    if (wasAntifreezeAdded === '') {
      return true;
    }
    if (wasAntifreezeAdded === 'yes') {
      if (liquidLiters.toString() === '0') {
        return true;
      }
    }
    return false;
  };

  const handleTaskUpdate = async () => {
    const updatedValues = {
      was_there_dry_cleaning: wasThereDryCleaning,
      was_antifreeze_added: wasAntifreezeAdded,
      antifreeze_liquid_liters_new: liquidLiters,
    };

    toast
      .promise(multipleTaskUpdate({ id: id, values: updatedValues }).unwrap(), {
        pending: 'Пожалуйста, подождите',
        success: 'Статусы обновлены 👌',
        error: 'Ошибка при обновлении статуса 🤯',
      })
      .then(() => {
        refetchTask();
        refetchNavigation();
      });
  };

  const getOptions = () => {
    return [
      { id: 'yes', label: 'Да' },
      { id: 'no', label: 'Нет' },
    ];
  };

  return (
    <Box sx={{ flex: 1, mt: 1, mr: 1, ml: 1 }}>
      <Autocomplete
        id="was_there_dry_cleaning"
        size="small"
        fullWidth
        disablePortal
        disableClearable
        options={getOptions()}
        renderInput={(params) => <TextField {...params} label={'Производилась ли химчистка?'} />}
        value={getOptions().find((item) => item.id == wasThereDryCleaning)}
        onChange={(event, newValue) => {
          setWasThereDryCleaning(newValue.id);
        }}
      />
      <Autocomplete
        id="was_antifreeze_added"
        size="small"
        fullWidth
        disablePortal
        disableClearable
        sx={{ mt: 1 }}
        options={getOptions()}
        renderInput={(params) => <TextField {...params} label={'Производилась ли заливка НЗ?'} />}
        value={getOptions().find((item) => item.id == wasAntifreezeAdded)}
        onChange={(event, newValue) => {
          setWasAntifreezeAdded(newValue.id);
        }}
      />
      {wasAntifreezeAdded === 'yes' && (
        <TextField
          label={'Количество залитых литров'}
          value={liquidLiters.toString() === '0' ? '' : liquidLiters}
          type={'number'}
          margin="normal"
          sx={{ mt: 1 }}
          fullWidth
          size="small"
          onChange={(e) => setLiquidLiters(e.target.value)}
        />
      )}
      <Box sx={{ position: 'fixed', bottom: '75px', left: '27px' }}>
        <Fab
          component="span"
          onClick={() => {
            refetchNavigation();
            refetchTask();
          }}
          color="primary"
        >
          <LuRefreshCcw size={24} />
        </Fab>
      </Box>
      <Box sx={{ position: 'fixed', bottom: '75px', right: '27px' }}>
        <Fab
          component="span"
          color="primary"
          disabled={uploadDisabled()}
          onClick={() => {
            handleTaskUpdate();
          }}
        >
          <LuUploadCloud size={24} />
        </Fab>
      </Box>
    </Box>
  );
};
