import React, { forwardRef, useImperativeHandle } from 'react';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import { IWheel } from '../../../../screens/types';
import {
  useLazyGetTireSizesQuery,
  useLazyGetTireBrandsQuery,
  useLazyGetTireModelsQuery,
  useLazyGetTireSeasonsQuery,
} from '../../../../redux/services/tasks';
import { DISK_TYPES, SEASON_CHOICES_MAP, SeasonKey } from '../../consts';
import { Controller, useForm } from 'react-hook-form';
import { IStageTireFitting } from '../../types';

export const BeforeAllWheelsHaveTheSameRubber = forwardRef(({ task, stage, tireRadiuses }: IStageTireFitting, ref) => {
  const desiredOrder = [
    'front_left' as 'front_left',
    'front_right' as 'front_right',
    'rear_left' as 'rear_left',
    'rear_right' as 'rear_right',
  ];

  const wheelsData = React.useMemo(() => {
    const wheelsFromTask =
      task?.wheels
        ?.filter((item) => item.stage === stage)
        .map((wheel) => ({
          task: task.id,
          brand: wheel.brand,
          model: wheel.model,
          size: wheel.size,
          radius: wheel.radius,
          season: wheel.season,
          disk: wheel.disk,
          stage: stage,
          position: wheel.position,
          reason: wheel.reason || '',
        })) || [];

    const filteredWheels =
      wheelsFromTask.length > 0
        ? wheelsFromTask
        : desiredOrder.map((position) => ({
            task: task.id,
            brand: '',
            model: '',
            size: '',
            radius: '',
            season: '',
            disk: '',
            stage: stage,
            position,
            reason: '',
          }));

    return filteredWheels.sort(
      (a, b) =>
        desiredOrder.indexOf(a.position as 'front_left' | 'front_right' | 'rear_left' | 'rear_right') -
        desiredOrder.indexOf(b.position as 'front_left' | 'front_right' | 'rear_left' | 'rear_right'),
    );
  }, [task, stage]);

  const {
    control,
    trigger,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm<{ wheels: IWheel[] }>({
    defaultValues: {
      wheels: wheelsData,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const [tireSizesList, tireSizesListResult] = useLazyGetTireSizesQuery();
  const [tireBrandsList, tireBrandsListResult] = useLazyGetTireBrandsQuery();
  const [tireModelsList, tireModelsListResult] = useLazyGetTireModelsQuery();
  const [tireSeasonsList, tireSeasonsListResult] = useLazyGetTireSeasonsQuery();

  React.useEffect(() => {
    if (tireSizesListResult.data?.length === 1) {
      [0, 1, 2, 3].forEach((index) =>
        setValue(
          `wheels.${index}.size`,
          tireSizesListResult.data !== undefined ? tireSizesListResult.data[0].size : '',
          { shouldValidate: true, shouldDirty: true },
        ),
      );
      tireBrandsList({ company: task.carsharing, radius: watch('wheels.0.radius'), size: watch('wheels.0.size') });
    }
  }, [tireSizesListResult.data]);

  React.useEffect(() => {
    if (tireBrandsListResult.data?.length === 1) {
      [0, 1, 2, 3].forEach((index) =>
        setValue(
          `wheels.${index}.brand`,
          tireBrandsListResult.data !== undefined ? tireBrandsListResult.data[0].brand : '',
          { shouldValidate: true, shouldDirty: true },
        ),
      );
      tireModelsList({
        company: task.carsharing,
        radius: watch('wheels.0.radius'),
        size: watch('wheels.0.size'),
        brand: watch('wheels.0.brand'),
      });
    }
  }, [tireBrandsListResult.data]);

  React.useEffect(() => {
    if (tireModelsListResult.data?.length === 1) {
      [0, 1, 2, 3].forEach((index) =>
        setValue(
          `wheels.${index}.model`,
          tireModelsListResult.data !== undefined ? tireModelsListResult.data[0].model : '',
          { shouldValidate: true, shouldDirty: true },
        ),
      );
      tireSeasonsList({
        company: task.carsharing,
        radius: watch('wheels.0.radius'),
        size: watch('wheels.0.size'),
        brand: watch('wheels.0.brand'),
        model: watch('wheels.0.model'),
      });
    }
  }, [tireModelsListResult.data]);

  React.useEffect(() => {
    if (tireSeasonsListResult.data?.length === 1) {
      [0, 1, 2, 3].forEach((index) =>
        setValue(
          `wheels.${index}.season`,
          tireSeasonsListResult.data !== undefined ? tireSeasonsListResult.data[0].season : '',
          { shouldValidate: true, shouldDirty: true },
        ),
      );
    }
  }, [tireSeasonsListResult.data]);

  React.useEffect(() => {
    if (watch('wheels.0.radius')) {
      tireSizesList({
        company: task.carsharing,
        radius: watch('wheels.0.radius'),
      });
    }
  }, [watch('wheels.0.radius'), tireSizesList]);

  React.useEffect(() => {
    if (watch('wheels.0.radius') && watch('wheels.0.size')) {
      tireBrandsList({ company: task.carsharing, radius: watch('wheels.0.radius'), size: watch('wheels.0.size') });
    }
  }, [watch('wheels.0.radius'), watch('wheels.0.size'), tireBrandsList]);

  React.useEffect(() => {
    if (watch('wheels.0.radius') && watch('wheels.0.size') && watch('wheels.0.brand')) {
      tireModelsList({
        company: task.carsharing,
        radius: watch('wheels.0.radius'),
        size: watch('wheels.0.size'),
        brand: watch('wheels.0.brand'),
      });
    }
  }, [watch('wheels.0.radius'), watch('wheels.0.size'), watch('wheels.0.brand'), tireModelsList]);

  React.useEffect(() => {
    if (watch('wheels.0.radius') && watch('wheels.0.size') && watch('wheels.0.brand') && watch('wheels.0.model')) {
      tireSeasonsList({
        company: task.carsharing,
        radius: watch('wheels.0.radius'),
        size: watch('wheels.0.size'),
        brand: watch('wheels.0.brand'),
        model: watch('wheels.0.model'),
      });
    }
  }, [
    watch('wheels.0.radius'),
    watch('wheels.0.size'),
    watch('wheels.0.brand'),
    watch('wheels.0.model'),
    tireSeasonsList,
  ]);

  // Делаем submit доступным для родителя
  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      // Если форма изменилась, запускаем валидацию
      const valid = await trigger();
      if (!valid) {
        return null;
      }
      return getValues('wheels');
    },
  }));

  return (
    <Box>
      <Typography sx={{ textAlign: 'center', marginBottom: 1 }}>Все колеса</Typography>
      <Controller
        name="wheels.0.radius"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            onChange={(event, newValue) => {
              if (newValue !== watch('wheels.0.radius')) {
                const fields: Array<keyof IWheel> = ['size', 'brand', 'model', 'season', 'disk'];
                [0, 1, 2, 3].forEach((index) =>
                  setValue(`wheels.${index}.radius`, newValue, { shouldValidate: true, shouldDirty: true }),
                );
                fields.forEach((field) =>
                  [0, 1, 2, 3].forEach((index) => setValue(`wheels.${index}.${field}` as const, '')),
                );
              }
            }}
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Радиус"
                error={!!errors.wheels?.[0]?.radius}
                helperText={errors.wheels?.[0]?.radius?.message}
              />
            )}
            options={tireRadiuses !== undefined ? tireRadiuses.map((option) => option.radius) : []}
          />
        )}
      />
      <Controller
        name="wheels.0.size"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            value={field.value || ''}
            disabled={tireSizesListResult.isFetching || tireSizesListResult.isLoading}
            onChange={(event, newValue) => {
              if (newValue !== watch('wheels.0.size')) {
                const fields: Array<keyof IWheel> = ['brand', 'model', 'season', 'disk'];
                [0, 1, 2, 3].forEach((index) =>
                  setValue(`wheels.${index}.size`, newValue, { shouldValidate: true, shouldDirty: true }),
                );
                fields.forEach((field) =>
                  [0, 1, 2, 3].forEach((index) => setValue(`wheels.${index}.${field}` as const, '')),
                );
              }
            }}
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Размер"
                error={!!errors.wheels?.[0]?.size}
                helperText={errors.wheels?.[0]?.size?.message}
              />
            )}
            options={
              tireSizesListResult.data !== undefined ? tireSizesListResult.data.map((option) => option.size) : []
            }
          />
        )}
      />
      <Controller
        name="wheels.0.brand"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            value={field.value || ''}
            disabled={tireBrandsListResult.isFetching || tireBrandsListResult.isLoading}
            onChange={(event, newValue) => {
              if (newValue !== watch('wheels.0.brand')) {
                const fields: Array<keyof IWheel> = ['model', 'season', 'disk'];
                [0, 1, 2, 3].forEach((index) =>
                  setValue(`wheels.${index}.brand`, newValue, { shouldValidate: true, shouldDirty: true }),
                );
                fields.forEach((field) =>
                  [0, 1, 2, 3].forEach((index) => setValue(`wheels.${index}.${field}` as const, '')),
                );
              }
            }}
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Марка"
                error={!!errors.wheels?.[0]?.brand}
                helperText={errors.wheels?.[0]?.brand?.message}
              />
            )}
            options={
              tireBrandsListResult.data !== undefined ? tireBrandsListResult.data.map((option) => option.brand) : []
            }
          />
        )}
      />
      <Controller
        name="wheels.0.model"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            value={field.value || ''}
            disabled={tireModelsListResult.isFetching || tireModelsListResult.isLoading}
            onChange={(event, newValue) => {
              if (newValue !== watch('wheels.0.model')) {
                const fields: Array<keyof IWheel> = ['season', 'disk'];
                [0, 1, 2, 3].forEach((index) =>
                  setValue(`wheels.${index}.model`, newValue, { shouldValidate: true, shouldDirty: true }),
                );
                fields.forEach((field) =>
                  [0, 1, 2, 3].forEach((index) => setValue(`wheels.${index}.${field}` as const, '')),
                );
              }
            }}
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Модель"
                error={!!errors.wheels?.[0]?.model}
                helperText={errors.wheels?.[0]?.model?.message}
              />
            )}
            options={
              tireModelsListResult.data !== undefined ? tireModelsListResult.data.map((option) => option.model) : []
            }
          />
        )}
      />
      <Controller
        name="wheels.0.season"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            value={field.value || ''}
            disabled={tireSeasonsListResult.isFetching || tireSeasonsListResult.isLoading}
            onChange={(event, newValue) => {
              if (newValue !== null && newValue !== watch('wheels.0.season')) {
                const fields: Array<keyof IWheel> = ['disk'];
                [0, 1, 2, 3].forEach((index) =>
                  setValue(`wheels.${index}.season`, newValue, { shouldValidate: true, shouldDirty: true }),
                );
                fields.forEach((field) =>
                  [0, 1, 2, 3].forEach((index) => setValue(`wheels.${index}.${field}` as const, '')),
                );
              }
            }}
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Сезонность"
                error={!!errors.wheels?.[0]?.season} // Отображение ошибки
                helperText={errors.wheels?.[0]?.season?.message}
              />
            )}
            options={
              tireSeasonsListResult.data !== undefined ? tireSeasonsListResult.data.map((option) => option.season) : []
            }
            getOptionLabel={(option) => SEASON_CHOICES_MAP[option as SeasonKey] || option} // Преобразование в человекочитаемый формат
          />
        )}
      />
      <Controller
        name="wheels.0.disk"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            onChange={(event, newValue) => {
              if (newValue.id !== field.value) {
                [0, 1, 2, 3].forEach((index) =>
                  setValue(`wheels.${index}.disk`, newValue.id, { shouldValidate: true, shouldDirty: true }),
                );
              }
            }}
            value={DISK_TYPES.find((option) => option.id === field.value) || { id: '', label: '' }}
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Тип диска"
                error={!!errors.wheels?.[0]?.disk} // Отображение ошибки
                helperText={errors.wheels?.[0]?.disk?.message}
              />
            )}
            options={DISK_TYPES}
          />
        )}
      />
    </Box>
  );
});
