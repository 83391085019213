import React, { forwardRef, useImperativeHandle } from 'react';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { IWheel } from '../../../../screens/types';
import {
  useLazyGetTireSizesQuery,
  useLazyGetTireBrandsQuery,
  useLazyGetTireModelsQuery,
  useLazyGetTireSeasonsQuery,
} from '../../../../redux/services/tasks';
import { DISK_TYPES, SEASON_CHOICES_MAP, SeasonKey } from '../../consts';
import { Controller, useForm } from 'react-hook-form';
import { IStageTireFitting } from '../../types';

export const BeforeFrontAndReadAreDifferent = forwardRef(({ task, stage, tireRadiuses }: IStageTireFitting, ref) => {
  const desiredOrder = [
    'front_left' as 'front_left',
    'front_right' as 'front_right',
    'rear_left' as 'rear_left',
    'rear_right' as 'rear_right',
  ];

  const wheelsData = React.useMemo(() => {
    const wheelsFromTask =
      task?.wheels
        ?.filter((item) => item.stage === stage)
        .map((wheel) => ({
          task: task.id,
          brand: wheel.brand,
          model: wheel.model,
          size: wheel.size,
          radius: wheel.radius,
          season: wheel.season,
          disk: wheel.disk,
          stage: stage,
          position: wheel.position,
          reason: wheel.reason || '',
        })) || [];

    const filteredWheels =
      wheelsFromTask.length > 0
        ? wheelsFromTask
        : desiredOrder.map((position) => ({
            task: task.id,
            brand: '',
            model: '',
            size: '',
            radius: '',
            season: '',
            disk: '',
            stage: stage,
            position,
            reason: '',
          }));

    return filteredWheels.sort(
      (a, b) =>
        desiredOrder.indexOf(a.position as 'front_left' | 'front_right' | 'rear_left' | 'rear_right') -
        desiredOrder.indexOf(b.position as 'front_left' | 'front_right' | 'rear_left' | 'rear_right'),
    );
  }, [task, stage]);

  const {
    control,
    trigger,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm<{ wheels: IWheel[] }>({
    defaultValues: {
      wheels: wheelsData,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const [tireSizesFrontAxleList, tireSizesFrontAxleListResult] = useLazyGetTireSizesQuery();
  const [tireSizesRearAxleList, tireSizesRearAxleListResult] = useLazyGetTireSizesQuery();
  const [tireBrandsFrontAxleList, tireBrandsFrontAxleListResult] = useLazyGetTireBrandsQuery();
  const [tireBrandsRearAxleList, tireBrandsRearAxleListResult] = useLazyGetTireBrandsQuery();
  const [tireModelsFrontAxleList, tireModelsFrontAxleListResult] = useLazyGetTireModelsQuery();
  const [tireModelsRearAxleList, tireModelsRearAxleListResult] = useLazyGetTireModelsQuery();
  const [tireSeasonsFrontAxleList, tireSeasonsFrontAxleListResult] = useLazyGetTireSeasonsQuery();
  const [tireSeasonsRearAxleList, tireSeasonsRearAxleListResult] = useLazyGetTireSeasonsQuery();

  React.useEffect(() => {
    if (tireSizesFrontAxleListResult.data?.length === 1) {
      [0, 1].forEach((index) =>
        setValue(
          `wheels.${index}.size`,
          tireSizesFrontAxleListResult.data !== undefined ? tireSizesFrontAxleListResult.data[0].size : '',
          { shouldValidate: true, shouldDirty: true },
        ),
      );
      tireBrandsFrontAxleList({
        company: task.carsharing,
        radius: watch('wheels.0.radius'),
        size: watch('wheels.0.size'),
      });
    }
  }, [tireSizesFrontAxleListResult.data]);

  React.useEffect(() => {
    if (tireSizesRearAxleListResult.data?.length === 1) {
      [2, 3].forEach((index) =>
        setValue(
          `wheels.${index}.size`,
          tireSizesRearAxleListResult.data !== undefined ? tireSizesRearAxleListResult.data[0].size : '',
          { shouldValidate: true, shouldDirty: true },
        ),
      );
      tireBrandsRearAxleList({
        company: task.carsharing,
        radius: watch('wheels.2.radius'),
        size: watch('wheels.2.size'),
      });
    }
  }, [tireSizesRearAxleListResult.data]);

  React.useEffect(() => {
    if (tireBrandsFrontAxleListResult.data?.length === 1) {
      [0, 1].forEach((index) =>
        setValue(
          `wheels.${index}.brand`,
          tireBrandsFrontAxleListResult.data !== undefined ? tireBrandsFrontAxleListResult.data[0].brand : '',
          { shouldValidate: true, shouldDirty: true },
        ),
      );
      tireModelsFrontAxleList({
        company: task.carsharing,
        radius: watch('wheels.0.radius'),
        size: watch('wheels.0.size'),
        brand: watch('wheels.0.brand'),
      });
    }
  }, [tireBrandsFrontAxleListResult.data]);

  React.useEffect(() => {
    if (tireBrandsRearAxleListResult.data?.length === 1) {
      [2, 3].forEach((index) =>
        setValue(
          `wheels.${index}.brand`,
          tireBrandsRearAxleListResult.data !== undefined ? tireBrandsRearAxleListResult.data[0].brand : '',
          { shouldValidate: true, shouldDirty: true },
        ),
      );
      tireModelsRearAxleList({
        company: task.carsharing,
        radius: watch('wheels.2.radius'),
        size: watch('wheels.2.size'),
        brand: watch('wheels.2.brand'),
      });
    }
  }, [tireBrandsRearAxleListResult.data]);

  React.useEffect(() => {
    if (tireModelsFrontAxleListResult.data?.length === 1) {
      [0, 1].forEach((index) =>
        setValue(
          `wheels.${index}.model`,
          tireModelsFrontAxleListResult.data !== undefined ? tireModelsFrontAxleListResult.data[0].model : '',
          { shouldValidate: true, shouldDirty: true },
        ),
      );
      tireSeasonsFrontAxleList({
        company: task.carsharing,
        radius: watch('wheels.0.radius'),
        size: watch('wheels.0.size'),
        brand: watch('wheels.0.brand'),
        model: watch('wheels.0.model'),
      });
    }
  }, [tireModelsFrontAxleListResult.data]);

  React.useEffect(() => {
    if (tireModelsRearAxleListResult.data?.length === 1) {
      [2, 3].forEach((index) =>
        setValue(
          `wheels.${index}.model`,
          tireModelsRearAxleListResult.data !== undefined ? tireModelsRearAxleListResult.data[0].model : '',
          { shouldValidate: true, shouldDirty: true },
        ),
      );
      tireSeasonsRearAxleList({
        company: task.carsharing,
        radius: watch('wheels.2.radius'),
        size: watch('wheels.2.size'),
        brand: watch('wheels.2.brand'),
        model: watch('wheels.2.model'),
      });
    }
  }, [tireModelsRearAxleListResult.data]);

  React.useEffect(() => {
    if (tireSeasonsFrontAxleListResult.data?.length === 1) {
      [0, 1].forEach((index) =>
        setValue(
          `wheels.${index}.season`,
          tireSeasonsFrontAxleListResult.data !== undefined ? tireSeasonsFrontAxleListResult.data[0].season : '',
          { shouldValidate: true, shouldDirty: true },
        ),
      );
    }
  }, [tireSeasonsFrontAxleListResult.data]);

  React.useEffect(() => {
    if (tireSeasonsRearAxleListResult.data?.length === 1) {
      [2, 3].forEach((index) =>
        setValue(
          `wheels.${index}.season`,
          tireSeasonsRearAxleListResult.data !== undefined ? tireSeasonsRearAxleListResult.data[0].season : '',
          { shouldValidate: true, shouldDirty: true },
        ),
      );
    }
  }, [tireSeasonsRearAxleListResult.data]);

  React.useEffect(() => {
    if (watch('wheels.0.radius')) {
      tireSizesFrontAxleList({
        company: task.carsharing,
        radius: watch('wheels.0.radius'),
      });
    }
  }, [watch('wheels.0.radius'), tireSizesFrontAxleList]);

  React.useEffect(() => {
    if (watch('wheels.2.radius')) {
      tireSizesRearAxleList({
        company: task.carsharing,
        radius: watch('wheels.2.radius'),
      });
    }
  }, [watch('wheels.2.radius'), tireSizesRearAxleList]);

  React.useEffect(() => {
    if (watch('wheels.0.radius') && watch('wheels.0.size')) {
      tireBrandsFrontAxleList({
        company: task.carsharing,
        radius: watch('wheels.0.radius'),
        size: watch('wheels.0.size'),
      });
    }
  }, [watch('wheels.0.radius'), watch('wheels.0.size'), tireBrandsFrontAxleList]);

  React.useEffect(() => {
    if (watch('wheels.2.radius') && watch('wheels.2.size')) {
      tireBrandsRearAxleList({
        company: task.carsharing,
        radius: watch('wheels.2.radius'),
        size: watch('wheels.2.size'),
      });
    }
  }, [watch('wheels.2.radius'), watch('wheels.2.size'), tireBrandsRearAxleList]);

  React.useEffect(() => {
    if (watch('wheels.0.radius') && watch('wheels.0.size') && watch('wheels.0.brand')) {
      tireModelsFrontAxleList({
        company: task.carsharing,
        radius: watch('wheels.0.radius'),
        size: watch('wheels.0.size'),
        brand: watch('wheels.0.brand'),
      });
    }
  }, [watch('wheels.0.radius'), watch('wheels.0.size'), watch('wheels.0.brand'), tireModelsFrontAxleList]);

  React.useEffect(() => {
    if (watch('wheels.2.radius') && watch('wheels.2.size') && watch('wheels.2.brand')) {
      tireModelsRearAxleList({
        company: task.carsharing,
        radius: watch('wheels.2.radius'),
        size: watch('wheels.2.size'),
        brand: watch('wheels.2.brand'),
      });
    }
  }, [watch('wheels.2.radius'), watch('wheels.2.size'), watch('wheels.2.brand'), tireModelsRearAxleList]);

  React.useEffect(() => {
    if (watch('wheels.0.radius') && watch('wheels.0.size') && watch('wheels.0.brand') && watch('wheels.0.model')) {
      tireSeasonsFrontAxleList({
        company: task.carsharing,
        radius: watch('wheels.0.radius'),
        size: watch('wheels.0.size'),
        brand: watch('wheels.0.brand'),
        model: watch('wheels.0.model'),
      });
    }
  }, [
    watch('wheels.0.radius'),
    watch('wheels.0.size'),
    watch('wheels.0.brand'),
    watch('wheels.0.model'),
    tireSeasonsFrontAxleList,
  ]);

  React.useEffect(() => {
    if (watch('wheels.2.radius') && watch('wheels.2.size') && watch('wheels.2.brand') && watch('wheels.2.model')) {
      tireSeasonsRearAxleList({
        company: task.carsharing,
        radius: watch('wheels.2.radius'),
        size: watch('wheels.2.size'),
        brand: watch('wheels.2.brand'),
        model: watch('wheels.2.model'),
      });
    }
  }, [
    watch('wheels.2.radius'),
    watch('wheels.2.size'),
    watch('wheels.2.brand'),
    watch('wheels.2.model'),
    tireSeasonsRearAxleList,
  ]);

  // Делаем submit доступным для родителя
  useImperativeHandle(ref, () => ({
    submitForm: async () => {
      // Если форма изменилась, запускаем валидацию
      const valid = await trigger();
      if (!valid) {
        return null;
      }
      return getValues('wheels');
    },
  }));

  return (
    <Box>
      <Typography sx={{ textAlign: 'center', marginBottom: 1 }}>Передняя ось</Typography>
      <Controller
        name="wheels.0.radius"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            onChange={(event, newValue) => {
              if (newValue !== watch('wheels.0.radius')) {
                const fields: Array<keyof IWheel> = ['size', 'brand', 'model', 'season', 'disk'];
                [0, 1, 2, 3].forEach((index) =>
                  setValue(`wheels.${index}.radius`, newValue, { shouldValidate: true, shouldDirty: true }),
                );
                fields.forEach((field) =>
                  [0, 1, 2, 3].forEach((index) => setValue(`wheels.${index}.${field}` as const, '')),
                );
              }
            }}
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Радиус"
                error={!!errors.wheels?.[0]?.radius}
                helperText={errors.wheels?.[0]?.radius?.message}
              />
            )}
            options={tireRadiuses !== undefined ? tireRadiuses.map((option) => option.radius) : []}
          />
        )}
      />
      <Controller
        name="wheels.0.size"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            value={field.value || ''}
            disabled={tireSizesFrontAxleListResult.isFetching || tireSizesFrontAxleListResult.isLoading}
            onChange={(event, newValue) => {
              if (newValue !== watch('wheels.0.size')) {
                const fields: Array<keyof IWheel> = ['brand', 'model', 'season', 'disk'];
                [0, 1, 2, 3].forEach((index) =>
                  setValue(`wheels.${index}.size`, newValue, { shouldValidate: true, shouldDirty: true }),
                );
                fields.forEach((field) =>
                  [0, 1, 2, 3].forEach((index) => setValue(`wheels.${index}.${field}` as const, '')),
                );
              }
            }}
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Размер"
                error={!!errors.wheels?.[0]?.size}
                helperText={errors.wheels?.[0]?.size?.message}
              />
            )}
            options={
              tireSizesFrontAxleListResult.data !== undefined
                ? tireSizesFrontAxleListResult.data.map((option) => option.size)
                : []
            }
          />
        )}
      />
      <Controller
        name="wheels.0.brand"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            value={field.value || ''}
            disabled={tireBrandsFrontAxleListResult.isFetching || tireBrandsFrontAxleListResult.isLoading}
            onChange={(event, newValue) => {
              if (newValue !== watch('wheels.0.brand')) {
                const fields: Array<keyof IWheel> = ['model', 'season', 'disk'];
                [0, 1].forEach((index) =>
                  setValue(`wheels.${index}.brand`, newValue, { shouldValidate: true, shouldDirty: true }),
                );
                fields.forEach((field) => [0, 1].forEach((index) => setValue(`wheels.${index}.${field}` as const, '')));
              }
            }}
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Марка"
                error={!!errors.wheels?.[0]?.brand}
                helperText={errors.wheels?.[0]?.brand?.message}
              />
            )}
            options={
              tireBrandsFrontAxleListResult.data !== undefined
                ? tireBrandsFrontAxleListResult.data.map((option) => option.brand)
                : []
            }
          />
        )}
      />
      <Controller
        name="wheels.0.model"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            value={field.value || ''}
            disabled={tireModelsFrontAxleListResult.isFetching || tireModelsFrontAxleListResult.isLoading}
            onChange={(event, newValue) => {
              if (newValue !== watch('wheels.0.model')) {
                const fields: Array<keyof IWheel> = ['season', 'disk'];
                [0, 1].forEach((index) =>
                  setValue(`wheels.${index}.model`, newValue, { shouldValidate: true, shouldDirty: true }),
                );
                fields.forEach((field) => [0, 1].forEach((index) => setValue(`wheels.${index}.${field}` as const, '')));
              }
            }}
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Модель"
                error={!!errors.wheels?.[0]?.model}
                helperText={errors.wheels?.[0]?.model?.message}
              />
            )}
            options={
              tireModelsFrontAxleListResult.data !== undefined
                ? tireModelsFrontAxleListResult.data.map((option) => option.model)
                : []
            }
          />
        )}
      />
      <Controller
        name="wheels.0.season"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            value={field.value || ''}
            disabled={tireSeasonsFrontAxleListResult.isFetching || tireSeasonsFrontAxleListResult.isLoading}
            onChange={(event, newValue) => {
              if (newValue !== null && newValue !== watch('wheels.0.season')) {
                const fields: Array<keyof IWheel> = ['disk'];
                [0, 1].forEach((index) =>
                  setValue(`wheels.${index}.season`, newValue, { shouldValidate: true, shouldDirty: true }),
                );
                fields.forEach((field) => [0, 1].forEach((index) => setValue(`wheels.${index}.${field}` as const, '')));
              }
            }}
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Сезонность"
                error={!!errors.wheels?.[0]?.season} // Отображение ошибки
                helperText={errors.wheels?.[0]?.season?.message}
              />
            )}
            options={
              tireSeasonsFrontAxleListResult.data !== undefined
                ? tireSeasonsFrontAxleListResult.data.map((option) => option.season)
                : []
            }
            getOptionLabel={(option) => SEASON_CHOICES_MAP[option as SeasonKey] || option} // Преобразование в человекочитаемый формат
          />
        )}
      />
      <Controller
        name="wheels.0.disk"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            onChange={(event, newValue) => {
              if (newValue.id !== field.value) {
                [0, 1].forEach((index) =>
                  setValue(`wheels.${index}.disk`, newValue.id, { shouldValidate: true, shouldDirty: true }),
                );
              }
            }}
            value={DISK_TYPES.find((option) => option.id === field.value) || { id: '', label: '' }}
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Тип диска"
                error={!!errors.wheels?.[0]?.disk} // Отображение ошибки
                helperText={errors.wheels?.[0]?.disk?.message}
              />
            )}
            options={DISK_TYPES}
          />
        )}
      />
      <Typography sx={{ textAlign: 'center', marginBottom: 1 }}>Задняя ось</Typography>
      <Controller
        name="wheels.2.radius"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            onChange={(event, newValue) => {
              if (newValue !== watch('wheels.2.radius')) {
                const fields: Array<keyof IWheel> = ['size', 'brand', 'model', 'season', 'disk'];
                [2, 3].forEach((index) =>
                  setValue(`wheels.${index}.radius`, newValue, { shouldValidate: true, shouldDirty: true }),
                );
                fields.forEach((field) => [2, 3].forEach((index) => setValue(`wheels.${index}.${field}` as const, '')));
              }
            }}
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Радиус"
                error={!!errors.wheels?.[2]?.radius}
                helperText={errors.wheels?.[2]?.radius?.message}
              />
            )}
            options={tireRadiuses !== undefined ? tireRadiuses.map((option) => option.radius) : []}
          />
        )}
      />
      <Controller
        name="wheels.2.size"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            value={field.value || ''}
            disabled={tireSizesRearAxleListResult.isFetching || tireSizesRearAxleListResult.isLoading}
            onChange={(event, newValue) => {
              if (newValue !== watch('wheels.2.size')) {
                const fields: Array<keyof IWheel> = ['brand', 'model', 'season', 'disk'];
                [2, 3].forEach((index) =>
                  setValue(`wheels.${index}.size`, newValue, { shouldValidate: true, shouldDirty: true }),
                );
                fields.forEach((field) => [2, 3].forEach((index) => setValue(`wheels.${index}.${field}` as const, '')));
              }
            }}
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Размер"
                error={!!errors.wheels?.[2]?.size}
                helperText={errors.wheels?.[2]?.size?.message}
              />
            )}
            options={
              tireSizesRearAxleListResult.data !== undefined
                ? tireSizesRearAxleListResult.data.map((option) => option.size)
                : []
            }
          />
        )}
      />
      <Controller
        name="wheels.2.brand"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            value={field.value || ''}
            disabled={tireBrandsRearAxleListResult.isFetching || tireBrandsRearAxleListResult.isLoading}
            onChange={(event, newValue) => {
              if (newValue !== watch('wheels.2.brand')) {
                const fields: Array<keyof IWheel> = ['model', 'season', 'disk'];
                [2, 3].forEach((index) =>
                  setValue(`wheels.${index}.brand`, newValue, { shouldValidate: true, shouldDirty: true }),
                );
                fields.forEach((field) => [2, 3].forEach((index) => setValue(`wheels.${index}.${field}` as const, '')));
              }
            }}
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Марка"
                error={!!errors.wheels?.[2]?.brand}
                helperText={errors.wheels?.[2]?.brand?.message}
              />
            )}
            options={
              tireBrandsRearAxleListResult.data !== undefined
                ? tireBrandsRearAxleListResult.data.map((option) => option.brand)
                : []
            }
          />
        )}
      />
      <Controller
        name="wheels.2.model"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            value={field.value || ''}
            disabled={tireModelsRearAxleListResult.isFetching || tireModelsRearAxleListResult.isLoading}
            onChange={(event, newValue) => {
              if (newValue !== watch('wheels.2.model')) {
                const fields: Array<keyof IWheel> = ['season', 'disk'];
                [2, 3].forEach((index) =>
                  setValue(`wheels.${index}.model`, newValue, { shouldValidate: true, shouldDirty: true }),
                );
                fields.forEach((field) => [2, 3].forEach((index) => setValue(`wheels.${index}.${field}` as const, '')));
              }
            }}
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Модель"
                error={!!errors.wheels?.[2]?.model}
                helperText={errors.wheels?.[2]?.model?.message}
              />
            )}
            options={
              tireModelsRearAxleListResult.data !== undefined
                ? tireModelsRearAxleListResult.data.map((option) => option.model)
                : []
            }
          />
        )}
      />
      <Controller
        name="wheels.2.season"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            value={field.value || ''}
            disabled={tireSeasonsRearAxleListResult.isFetching || tireSeasonsRearAxleListResult.isLoading}
            onChange={(event, newValue) => {
              if (newValue !== null && newValue !== watch('wheels.2.season')) {
                const fields: Array<keyof IWheel> = ['disk'];
                [2, 3].forEach((index) =>
                  setValue(`wheels.${index}.season`, newValue, { shouldValidate: true, shouldDirty: true }),
                );
                fields.forEach((field) => [2, 3].forEach((index) => setValue(`wheels.${index}.${field}` as const, '')));
              }
            }}
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Сезонность"
                error={!!errors.wheels?.[2]?.season} // Отображение ошибки
                helperText={errors.wheels?.[2]?.season?.message}
              />
            )}
            options={
              tireSeasonsRearAxleListResult.data !== undefined
                ? tireSeasonsRearAxleListResult.data.map((option) => option.season)
                : []
            }
            getOptionLabel={(option) => SEASON_CHOICES_MAP[option as SeasonKey] || option} // Преобразование в человекочитаемый формат
          />
        )}
      />
      <Controller
        name="wheels.2.disk"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            onChange={(event, newValue) => {
              if (newValue.id !== field.value) {
                [2, 3].forEach((index) =>
                  setValue(`wheels.${index}.disk`, newValue.id, { shouldValidate: true, shouldDirty: true }),
                );
              }
            }}
            value={DISK_TYPES.find((option) => option.id === field.value) || { id: '', label: '' }}
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Тип диска"
                error={!!errors.wheels?.[2]?.disk} // Отображение ошибки
                helperText={errors.wheels?.[2]?.disk?.message}
              />
            )}
            options={DISK_TYPES}
          />
        )}
      />
    </Box>
  );
});
