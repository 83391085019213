import { API_ENDPOINTS } from '../../config';
import {
  IDataPostDelete,
  IImageUpload,
  IOpenTask,
  IPostDismantlingAfterTaskCreate,
  IPostDismantlingAfterTaskResponse,
  IPostTireFittingAfterTaskCreate,
  IPostTireFittingBeforeTaskCreate,
  IPostTireFittingBeforeTaskResponse,
  IPostWithdrawalFromPaidParking,
  IPostWithdrawalFromTheImpoundLot,
  IResponseCarsByCarsharingList,
  IResponseCarsharingList,
  IResponseFieldListByCarsharingAndTypes,
  IResponseFieldListToDrawer,
  IResponseNavigation,
  IResponseTasks,
  IResponseTaskTypesList,
  ITaskCreate,
  IVideoPresignedUpload,
  IVideoPresignedUploadResponse,
  IWheel,
  IWithdrawalFromPaidParking,
  IWithdrawalFromTheImpoundLot,
  TResponseTireBrandByCarsharingList,
  TResponseTireModelByCarsharingList,
  TResponseTireRadiuses,
  TResponseTireSeasonByCarsharingList,
  TResponseTireSizeByCarsharingList,
  TTask,
} from '../../screens/types';
import { api } from './api';

export const tasksApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOpenTasks: builder.query<IResponseTasks, void>({
      query: () => ({
        url: API_ENDPOINTS.GET_OPEN_TASKS_ENDPOINT,
        method: 'GET',
      }),
    }),
    getOpenTask: builder.query<IOpenTask, string>({
      query: (id) => ({
        url: `${API_ENDPOINTS.GET_OPEN_TASK_ENDPOINT}${id}/`,
        method: 'GET',
      }),
    }),
    getNavigationTask: builder.query<IResponseNavigation, string>({
      query: (id) => ({
        url: `${API_ENDPOINTS.GET_NAVIGATION_TASK_ENDPOINT}?id=${id}`,
        method: 'GET',
      }),
    }),
    getCloseTasks: builder.query<IResponseTasks, void>({
      query: () => ({
        url: API_ENDPOINTS.GET_COMPLETED_TASKS_ENDPOINT,
        method: 'GET',
      }),
    }),
    getCarsharingList: builder.query<IResponseCarsharingList, void>({
      query: () => ({
        url: API_ENDPOINTS.GET_CARSHARING_ENDPOINT,
        method: 'GET',
      }),
    }),
    getTypesByCarsharing: builder.query<IResponseTaskTypesList[], string>({
      query: (carsharing) => ({
        url: `${API_ENDPOINTS.GET_TYPES_BY_CARSHARING_ENDPOINT}?carsharing=${carsharing}&source=web`, //TODO: change app to web
        method: 'GET',
      }),
    }),
    getCarsByCarsharing: builder.query<IResponseCarsByCarsharingList, string>({
      query: (carsharing) => ({
        url: `${API_ENDPOINTS.GET_CARS_BY_CARSHARING_ENDPOINT}?carsharing=${carsharing}`,
        method: 'GET',
      }),
    }),
    getTireSizes: builder.query<TResponseTireSizeByCarsharingList[], { company: string; radius: string }>({
      query: ({ company, radius }) => ({
        url: `${API_ENDPOINTS.GET_SIZE_BY_CARSHARING_AND_BRAND_AND_MODEL_ENDPOINT}?company=${company}&radius=${radius}`,
        method: 'GET',
      }),
    }),
    getTireRadiuses: builder.query<TResponseTireRadiuses[], { company: string }>({
      query: ({ company }) => ({
        url: `${API_ENDPOINTS.GET_RADIUSES_BY_CARSHARING_AND_BRAND_AND_MODEL_ENDPOINT}?company=${company}`,
        method: 'GET',
      }),
    }),
    getTireBrands: builder.query<
      TResponseTireBrandByCarsharingList[],
      { company: string; radius: string; size: string }
    >({
      query: ({ company, radius, size }) => ({
        url: `${API_ENDPOINTS.GET_BRAND_TIRES_BY_CARSHARING_ENDPOINT}?company=${company}&radius=${radius}&size=${size}`,
        method: 'GET',
      }),
    }),
    getTireModels: builder.query<
      TResponseTireModelByCarsharingList[],
      { company: string; radius: string; size: string; brand: string }
    >({
      query: ({ company, radius, size, brand }) => ({
        url: `${API_ENDPOINTS.GET_BRAND_MODELS_BY_CARSHARING_AND_BRAND_ENDPOINT}?company=${company}&radius=${radius}&size=${size}&brand=${brand}`,
        method: 'GET',
      }),
    }),
    getTireSeasons: builder.query<
      TResponseTireSeasonByCarsharingList[],
      { company: string; brand: string; model: string; size: string; radius: string }
    >({
      query: ({ company, brand, model, size, radius }) => ({
        url: `${API_ENDPOINTS.GET_SEASON_BY_CARSHARING_AND_BRAND_AND_MODEL_ENDPOINT}?company=${company}&brand=${brand}&model=${model}&size=${size}&radius=${radius}`,
        method: 'GET',
      }),
    }),
    getFieldListByCarsharingAndTypes: builder.query<
      IResponseFieldListByCarsharingAndTypes,
      { carsharing: string; task_type: string; direction?: string }
    >({
      query: ({ carsharing, task_type, direction }) => ({
        url: `${API_ENDPOINTS.GET_FIELD_LIST_BY_CARSHARING_AND_TYPE_ENDPOINT}?carsharing=${carsharing}&task=${task_type}&direction=${direction}`,
        method: 'GET',
      }),
    }),
    getAutoAndTypeFleet: builder.query<
      { car: string; car_plate: string; vehicle_fleet: string },
      { car_plate: string | undefined; carsharing: string | undefined }
    >({
      query: ({ car_plate, carsharing }) => ({
        url: `${API_ENDPOINTS.GET_AUTO_AND_TYPE_FLEET}?car_plate=${car_plate}&company=${carsharing}`,
        method: 'GET',
      }),
    }),
    getFieldToDrawerTask: builder.query<
      IResponseFieldListToDrawer,
      { carsharing?: string; task_type?: string; id?: string }
    >({
      query: ({ carsharing, task_type, id }) => ({
        url: `${API_ENDPOINTS.GET_FIELD_LIST_TO_DRAWER_ENDPOINT}?carsharing=${carsharing}&task=${task_type}&task_id=${id}`,
        method: 'GET',
      }),
    }),
    postDeleteImage: builder.mutation<void, IDataPostDelete>({
      query: ({ ids, apiLink }) => ({
        url: `${apiLink}delete/`,
        method: 'POST',
        body: { ids: ids },
      }),
    }),
    postDeleteTask: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `${API_ENDPOINTS.GET_OPEN_TASK_ENDPOINT}delete/`,
        method: 'POST',
        body: { ids: id },
      }),
    }),
    postCreateTask: builder.mutation<TTask, ITaskCreate>({
      query: (task) => ({
        url: `${API_ENDPOINTS.GET_OPEN_TASK_ENDPOINT}`,
        method: 'POST',
        body: task,
      }),
    }),
    postTireFittingBeforeTaskCreate: builder.mutation<
      IPostTireFittingBeforeTaskResponse,
      IPostTireFittingBeforeTaskCreate
    >({
      query: (data) => ({
        url: `${API_ENDPOINTS.POST_TIRE_FITTING_BEFORE_TASK_ENDPOINT}`,
        method: 'POST',
        body: data,
      }),
    }),
    postTireFittingAfterTaskCreate: builder.mutation<
      IPostTireFittingBeforeTaskResponse,
      IPostTireFittingAfterTaskCreate
    >({
      query: (data) => ({
        url: `${API_ENDPOINTS.POST_TIRE_FITTING_AFTER_TASK_ENDPOINT}`,
        method: 'POST',
        body: data,
      }),
    }),
    postDismantlingAfterTaskCreate: builder.mutation<
      IPostDismantlingAfterTaskResponse,
      IPostDismantlingAfterTaskCreate
    >({
      query: (data) => ({
        url: `${API_ENDPOINTS.POST_DISMANTLING_AFTER_TASK_ENDPOINT}`,
        method: 'POST',
        body: data,
      }),
    }),
    postWheelCreate: builder.mutation<IWheel[], IWheel[]>({
      query: (data) => ({
        url: `${API_ENDPOINTS.POST_WHEELS_ENDPOINT}`,
        method: 'POST',
        body: data,
      }),
    }),
    postUploadImage: builder.mutation<void, IImageUpload>({
      query: ({ apiLink, formData }) => ({
        url: `${apiLink}`,
        method: 'POST',
        body: formData,
      }),
    }),
    getPresignedLinkForVideoUpload: builder.mutation<IVideoPresignedUploadResponse, IVideoPresignedUpload>({
      query: ({ apiLink, formData }) => ({
        url: `${apiLink}`,
        method: 'POST',
        body: formData,
      }),
    }),
    updateTask: builder.mutation<
      void,
      {
        key:
          | 'location_keys'
          | 'mileage_before_task'
          | 'mileage_after_task'
          | 'was_there_dry_cleaning'
          | 'was_antifreeze_added'
          | 'status'
          | 'photobeforetransit_set'
          | 'photoaftertransit_set'
          | 'photobeforewash_set'
          | 'photoafterwash_set'
          | 'photobeforecleaning_set'
          | 'photoaftercleaning_set'
          | 'photobeforemileage_set'
          | 'photoaftermileage_set'
          | 'photobeforetire_set'
          | 'photoaftertire_set'
          | 'photototal_set'
          | 'photodamage_set'
          | 'photonavigator_set'
          | 'mileage_before_task'
          | 'mileage_after_task'
          | 'antifreeze_liquid_liters_new'
          | 'transit_season_tire_before'
          | 'car_condition'
          | 'car_condition_after'
          | 'car_condition_comment'
          | 'car_condition_comment_after'
          | 'transit_season_tire_after';
        value: string;
        id: string | undefined;
      }
    >({
      query: ({ key, value, id }) => ({
        url: `${API_ENDPOINTS.GET_OPEN_TASK_ENDPOINT}` + `${id}/`,
        method: 'PATCH',
        body: { [key]: value },
      }),
    }),
    multipleUpdateTask: builder.mutation<
      void,
      {
        values: Partial<
          Record<
            | 'location_keys'
            | 'mileage_before_task'
            | 'mileage_after_task'
            | 'was_there_dry_cleaning'
            | 'was_antifreeze_added'
            | 'status'
            | 'photobeforetransit_set'
            | 'photoaftertransit_set'
            | 'photobeforewash_set'
            | 'photoafterwash_set'
            | 'photobeforecleaning_set'
            | 'photoaftercleaning_set'
            | 'photobeforemileage_set'
            | 'photoaftermileage_set'
            | 'photobeforetire_set'
            | 'photoaftertire_set'
            | 'photototal_set'
            | 'photodamage_set'
            | 'photonavigator_set'
            | 'antifreeze_liquid_liters_new'
            | 'transit_season_tire_before'
            | 'car_condition'
            | 'car_condition_after'
            | 'car_condition_comment'
            | 'car_condition_comment_after'
            | 'transit_season_tire_after',
            string
          >
        >;

        id: string | undefined;
      }
    >({
      query: ({ values, id }) => ({
        url: `${API_ENDPOINTS.GET_OPEN_TASK_ENDPOINT}${id}/`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      }),
    }),
    postAfterWithdrawalFromPaidParkingCreate: builder.mutation<
      IWithdrawalFromPaidParking,
      IPostWithdrawalFromPaidParking
    >({
      query: (data) => ({
        url: `${API_ENDPOINTS.POST_WITHDRAWAL_FROM_PAID_PARKING_AFTER_TASK_ENDPOINT}`,
        method: 'POST',
        body: data,
      }),
    }),
    postAfterWithdrawalFromTheImpoundLotCreate: builder.mutation<
      IWithdrawalFromTheImpoundLot,
      IPostWithdrawalFromTheImpoundLot
    >({
      query: (data) => ({
        url: `${API_ENDPOINTS.POST_WITHDRAWAL_FROM_THE_IMPOUND_LOT_AFTER_TASK_ENDPOINT}`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  usePostUploadImageMutation,
  useGetPresignedLinkForVideoUploadMutation,
  usePostDeleteImageMutation,
  useUpdateTaskMutation,
  useMultipleUpdateTaskMutation,
  usePostDeleteTaskMutation,
  usePostCreateTaskMutation,
  usePostTireFittingBeforeTaskCreateMutation,
  usePostTireFittingAfterTaskCreateMutation,
  usePostDismantlingAfterTaskCreateMutation,
  usePostWheelCreateMutation,
  usePostAfterWithdrawalFromPaidParkingCreateMutation,
  usePostAfterWithdrawalFromTheImpoundLotCreateMutation,
  useGetOpenTaskQuery,
  useGetOpenTasksQuery,
  useLazyGetFieldToDrawerTaskQuery,
  useGetCloseTasksQuery,
  useGetNavigationTaskQuery,
  useGetCarsharingListQuery,
  useLazyGetTypesByCarsharingQuery,
  useLazyGetTireRadiusesQuery,
  useLazyGetTireSizesQuery,
  useLazyGetTireBrandsQuery,
  useLazyGetTireModelsQuery,
  useLazyGetTireSeasonsQuery,
  useLazyGetCarsByCarsharingQuery,
  useLazyGetFieldListByCarsharingAndTypesQuery,
  useLazyGetAutoAndTypeFleetQuery,
} = tasksApi;

export const {
  endpoints: { getOpenTasks, getCloseTasks },
} = tasksApi;
