import { Autocomplete, Box, Fab, TextField } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LuRefreshCcw, LuUploadCloud } from 'react-icons/lu';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  useLazyGetTireRadiusesQuery,
  usePostTireFittingBeforeTaskCreateMutation,
  usePostWheelCreateMutation,
} from '../../../redux/services/tasks';
import { IPostTireFittingBeforeTaskCreate, IWheel, TBeforeTire } from '../../../screens/types';
import { optionsHowWheelsMounted, optionsNumberDamagedWheels } from '../consts';
import { IBeforeAndAfterTireFitting } from '../types';
import { BeforeAllWheelsHaveTheSameRubber } from '../tire-fitting/before/before-all-wheels-have-the-same-rubber';
import { BeforeFrontAndReadAreDifferent } from '../tire-fitting/before/before-front-and-read-are-different';
import { BeforeEverythingIsDifferent } from '../tire-fitting/before/before-everything-is-different';

export const BeforeTransitSeasonTireFitting: React.FC<IBeforeAndAfterTireFitting> = ({
  task,
  refetchTask,
  refetchNavigation,
}) => {
  const { control, handleSubmit, watch, setValue, getValues } = useForm<IPostTireFittingBeforeTaskCreate>({
    defaultValues: {
      task: task.id,
      how_are_the_wheels_mounted_on_the_car: '',
      how_many_wheels_are_damaged: '0',
      is_front_left_wheel_damaged: 'no',
      is_front_right_wheel_damaged: 'no',
      is_rear_left_wheel_damaged: 'no',
      is_rear_right_wheel_damaged: 'no',
      damaged_wheels: [],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const wheelFormRef = React.useRef<{ submitForm: () => IWheel[] } | null>(null);

  const [wheelCreate, wheelCreateResult] = usePostWheelCreateMutation();
  // prettier-ignore
  const [postTireFittingBeforeTaskCreate, postTireFittingBeforeTaskCreateResult] = usePostTireFittingBeforeTaskCreateMutation();
  const [tireRadiuses, tireRadiusesResult] = useLazyGetTireRadiusesQuery();

  React.useEffect(() => {
    if (task?.carsharing) {
      tireRadiuses({ company: task.carsharing });
      if (task.before_tire.length !== 0) {
        setValue('how_are_the_wheels_mounted_on_the_car', task.before_tire[0].how_are_the_wheels_mounted_on_the_car);
      }
    }
  }, []);

  const onSubmit = async (parentData: IPostTireFittingBeforeTaskCreate) => {
    try {
      const wheelsData = await wheelFormRef.current?.submitForm();
      if (!wheelsData) return;

      const beforeTireData = parentData;
      await toast.promise(
        postTireFittingBeforeTaskCreate({
          ...beforeTireData,
        }).unwrap(),
        {
          success: 'Данные конфигурации успешно отправлены 👌',
          pending: 'Пожалуйста, подождите',
          error: 'Ошибка при отправке данных конфигурации 🤯',
        },
      );
      await toast.promise(wheelCreate(wheelsData).unwrap(), {
        success: 'Данные по колесам успешно отправлены 👌',
        pending: 'Пожалуйста, подождите',
        error: 'Ошибка при отправке данных по колесам 🤯',
      });
      refetchTask();
      refetchNavigation();
    } catch (error) {
      toast.error('Проверьте данные формы');
      console.error('Ошибка валидации колес:', error);
    }
  };

  return (
    <Box sx={{ flex: 1, mt: 1, mr: 1, ml: 1 }}>
      <Controller
        name="how_are_the_wheels_mounted_on_the_car"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            onChange={(event, newValue) => {
              if (newValue.id !== field.value) {
                setValue('how_are_the_wheels_mounted_on_the_car', newValue.id, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }
            }}
            value={optionsHowWheelsMounted.find((option) => option.id === field.value) || { id: '', label: '' }}
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Как на машине установлена резина"
                error={!!error}
                helperText={error ? error.message : ''}
              />
            )}
            options={optionsHowWheelsMounted}
          />
        )}
      />
      {watch('how_are_the_wheels_mounted_on_the_car') === 'all_wheels_have_the_same_rubber' && (
        <Box sx={{ paddingBottom: '64px' }}>
          <BeforeAllWheelsHaveTheSameRubber
            ref={wheelFormRef}
            task={task}
            stage="before"
            tireRadiuses={tireRadiusesResult.data}
          />
        </Box>
      )}
      {watch('how_are_the_wheels_mounted_on_the_car') === 'front_and_rear_are_different' && (
        <Box sx={{ paddingBottom: '64px' }}>
          <BeforeFrontAndReadAreDifferent
            ref={wheelFormRef}
            task={task}
            stage="before"
            tireRadiuses={tireRadiusesResult.data}
          />
        </Box>
      )}
      {watch('how_are_the_wheels_mounted_on_the_car') === 'everything_is_different' && (
        <Box sx={{ paddingBottom: '64px' }}>
          <BeforeEverythingIsDifferent
            ref={wheelFormRef}
            task={task}
            stage="before"
            tireRadiuses={tireRadiusesResult.data}
          />
        </Box>
      )}
      <Box sx={{ position: 'fixed', bottom: '75px', left: '27px', zIndex: 10 }}>
        <Fab
          component="span"
          onClick={() => {
            refetchNavigation();
            refetchTask();
          }}
          color="primary"
        >
          <LuRefreshCcw size={24} />
        </Fab>
      </Box>
      <Box sx={{ position: 'fixed', bottom: '75px', right: '27px' }}>
        <Fab
          component="span"
          color="primary"
          disabled={wheelCreateResult.isLoading || postTireFittingBeforeTaskCreateResult.isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          <LuUploadCloud size={24} />
        </Fab>
      </Box>
    </Box>
  );
};
