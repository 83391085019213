import { Autocomplete, Box, Fab, TextField } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LuRefreshCcw, LuUploadCloud } from 'react-icons/lu';
import { toast } from 'react-toastify';
import {
  useLazyGetTireRadiusesQuery,
  usePostTireFittingAfterTaskCreateMutation,
  usePostWheelCreateMutation,
} from '../../../redux/services/tasks';
import { IPostTireFittingAfterTaskCreate, IWheel } from '../../../screens/types';
import {
  optionsAreTheWheelsAndTiresTheSameBrandAndType,
  optionsHowWheelsMounted,
  optionsNumberDamagedWheels,
} from '../consts';
import { IBeforeAndAfterTireFitting } from '../types';
import { AfterAllWheelsHaveTheSameRubber } from './after/after-all-wheels-have-the-same-rubber';
import { AfterAreTheWheelsAndTireTheSameBrandAndType } from './after/after-are-the-wheels-and-tires-the-same-brand-and-type';
import { AfterEverythingIsDifferent } from './after/after-everything-is-different';
import { AfterFrontAndReadAreDifferent } from './after/after-front-and-read-are-different';

export const AfterTireFitting: React.FC<IBeforeAndAfterTireFitting> = ({ task, refetchTask, refetchNavigation }) => {
  const { control, handleSubmit, watch, setValue } = useForm<IPostTireFittingAfterTaskCreate>({
    defaultValues: {
      task: task.id,
      are_the_wheels_and_tires_the_same_brand_and_type: '',
      how_are_the_wheels_installed_on_the_car_after_replacement: '',
      how_many_wheels_are_replaced: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const wheelFormRef = React.useRef<{ submitForm: () => IWheel[] } | null>(null);

  const [wheelCreate, wheelCreateResult] = usePostWheelCreateMutation();
  // prettier-ignore
  const [postTireFittingAfterTaskCreate, postTireFittingAfterTaskCreateResult] = usePostTireFittingAfterTaskCreateMutation();
  const [tireRadiuses, tireRadiusesResult] = useLazyGetTireRadiusesQuery();

  React.useEffect(() => {
    if (task?.carsharing) {
      tireRadiuses({ company: task.carsharing });
      if (task.after_tire.length !== 0) {
        setValue(
          'are_the_wheels_and_tires_the_same_brand_and_type',
          task.after_tire[0].are_the_wheels_and_tires_the_same_brand_and_type,
        );
        setValue(
          'how_are_the_wheels_installed_on_the_car_after_replacement',
          task.after_tire[0].how_are_the_wheels_installed_on_the_car_after_replacement,
        );
        setValue('how_many_wheels_are_replaced', task.after_tire[0].how_many_wheels_are_replaced);
      }
    }
  }, []);

  const onSubmit = async (afterTireData: IPostTireFittingAfterTaskCreate) => {
    try {
      const wheelsData = await wheelFormRef.current?.submitForm();
      if (!wheelsData) {
        return;
      }
      await toast.promise(postTireFittingAfterTaskCreate(afterTireData).unwrap(), {
        success: 'Данные конфигурации успешно отправлены 👌',
        pending: 'Пожалуйста, подождите',
        error: 'Ошибка при отправке данных конфигурации 🤯',
      });
      await toast.promise(wheelCreate(wheelsData).unwrap(), {
        success: 'Данные по колесам успешно отправлены 👌',
        pending: 'Пожалуйста, подождите',
        error: 'Ошибка при отправке данных по колесам 🤯',
      });
      refetchTask();
      refetchNavigation();
    } catch (error) {
      toast.error('Проверьте данные формы');
      console.error('Ошибка валидации колес:', error);
    }
  };

  return (
    <Box sx={{ flex: 1, mt: 1, mr: 1, ml: 1 }}>
      <Controller
        name="how_many_wheels_are_replaced"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            onChange={(event, newValue) => {
              if (newValue.id !== field.value) {
                setValue('how_many_wheels_are_replaced', newValue.id, { shouldValidate: true, shouldDirty: true });
              }
            }}
            value={optionsNumberDamagedWheels.find((option) => option.label === field.value) || { id: '', label: '' }}
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option.label === value.label}
            renderInput={(params) => (
              <TextField {...params} label="Количество заменённых колес" error={!!error} helperText={error?.message} />
            )}
            options={optionsNumberDamagedWheels}
          />
        )}
      />
      <Controller
        name="are_the_wheels_and_tires_the_same_brand_and_type"
        control={control}
        rules={{ required: 'Обязательно для заполнения' }}
        render={({ field, fieldState: { error } }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth
            disableClearable
            sx={{ marginBottom: 2 }}
            onChange={(event, newValue) => {
              if (newValue.id !== field.value) {
                setValue('are_the_wheels_and_tires_the_same_brand_and_type', newValue.id, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
                setValue('how_are_the_wheels_installed_on_the_car_after_replacement', '', {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }
            }}
            value={
              optionsAreTheWheelsAndTiresTheSameBrandAndType.find((option) => option.id === field.value) || {
                id: '',
                label: '',
              }
            }
            noOptionsText="Не найдено"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Диски и резина остались той же марки и вида"
                error={!!error}
                helperText={error ? error.message : ''}
              />
            )}
            options={optionsAreTheWheelsAndTiresTheSameBrandAndType}
          />
        )}
      />
      {watch('are_the_wheels_and_tires_the_same_brand_and_type') === 'no' && (
        <>
          <Controller
            name="how_are_the_wheels_installed_on_the_car_after_replacement"
            control={control}
            rules={{ required: 'Обязательно для заполнения' }}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                size="small"
                fullWidth
                disableClearable
                sx={{ marginBottom: 2 }}
                onChange={(event, newValue) => {
                  if (newValue.id !== field.value) {
                    setValue('how_are_the_wheels_installed_on_the_car_after_replacement', newValue.id, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }
                }}
                value={optionsHowWheelsMounted.find((option) => option.id === field.value) || { id: '', label: '' }}
                noOptionsText="Не найдено"
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Как на машине установлена резина после замены"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
                options={optionsHowWheelsMounted}
              />
            )}
          />
        </>
      )}
      {watch('are_the_wheels_and_tires_the_same_brand_and_type') === 'yes' && (
        <Box sx={{ paddingBottom: '64px' }}>
          <AfterAreTheWheelsAndTireTheSameBrandAndType
            ref={wheelFormRef}
            task={task}
            stage="after"
            tireRadiuses={tireRadiusesResult.data}
          />
        </Box>
      )}
      {watch('are_the_wheels_and_tires_the_same_brand_and_type') === 'no' &&
        watch('how_are_the_wheels_installed_on_the_car_after_replacement') === 'all_wheels_have_the_same_rubber' && (
          <Box sx={{ paddingBottom: '64px' }}>
            <AfterAllWheelsHaveTheSameRubber
              ref={wheelFormRef}
              task={task}
              stage="after"
              tireRadiuses={tireRadiusesResult.data}
            />
          </Box>
        )}
      {watch('are_the_wheels_and_tires_the_same_brand_and_type') === 'no' &&
        watch('how_are_the_wheels_installed_on_the_car_after_replacement') === 'front_and_rear_are_different' && (
          <Box sx={{ paddingBottom: '64px' }}>
            <AfterFrontAndReadAreDifferent
              ref={wheelFormRef}
              task={task}
              stage="after"
              tireRadiuses={tireRadiusesResult.data}
            />
          </Box>
        )}
      {watch('are_the_wheels_and_tires_the_same_brand_and_type') === 'no' &&
        watch('how_are_the_wheels_installed_on_the_car_after_replacement') === 'everything_is_different' && (
          <Box sx={{ paddingBottom: '64px' }}>
            <AfterEverythingIsDifferent
              ref={wheelFormRef}
              task={task}
              stage="after"
              tireRadiuses={tireRadiusesResult.data}
            />
          </Box>
        )}
      <Box sx={{ position: 'fixed', bottom: '75px', left: '27px', zIndex: 10 }}>
        <Fab
          component="span"
          onClick={() => {
            refetchNavigation();
            refetchTask();
          }}
          color="primary"
        >
          <LuRefreshCcw size={24} />
        </Fab>
      </Box>
      <Box sx={{ position: 'fixed', bottom: '75px', right: '27px' }}>
        <Fab
          component="span"
          color="primary"
          disabled={wheelCreateResult.isLoading || postTireFittingAfterTaskCreateResult.isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          <LuUploadCloud size={24} />
        </Fab>
      </Box>
    </Box>
  );
};
